// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="CepPOS Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay CepPOS',
            headerTitle: 'CepPOS',
            title: "AHL Pay CepPOS",
            shortDescription:
                'Avantajlı komisyon oranları ile karını maksimize et; NFC, link, QR ve ya e-kart bilgisi ile ödeme al. AHL Pay CepPOS, yüksek güvenlik standartlarını karşılayarak müşterilerin için güvenli bir ödeme ortamı sağlar.',
            description:
                'Cep telefonundan ödeme almak artık çok daha hızlı. AHL Pay CepPOS ile dilediğin yerden Türk Lirası, Dolar ve Euro seçenekleri ile temassız ödeme al.',
            imagePath: 'assets/uploads/products/products/kurumsal_ceppos.png',
            title2: <><span>AHL Pay </span>CepPOS</>,
            childrens: [
                {
                    title: 'Hızlı ve Kolay Ödeme Çözümleri',
                    desc: 'İhtiyacın olan tüm özelliklere tek tıkla ulaşarak ödemelerini anında al. Mobil cihazını kullanarak, müşterilerine pratik ve hızlı bir ödeme deneyimi sun.'
                },
                {
                    title: 'Lokasyon Bağımsız',
                    desc: 'CepPOS ile dilediğin her yerde zaman kaybetmeden işlemlerini gerçekleştir.'
                },
                {
                    title: 'Güvenli ve Şeffaf İşlemler',
                    desc: 'CepPOS ile yapılan her işlem güvenilir ve şeffaf bir şekilde kaydedilir, müşterilerinin bilgileri her zaman korunur.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay CepPOS',
            headerTitle: 'CepPOS',
            title: "AHL Pay CepPOS",
            shortDescription:
                'Maximize your profit with advantageous commission rates; accept payments via NFC, link, QR, or e-card information. AHL Pay CepPOS provides a secure payment environment for your customers by meeting high security standards.',
            description:
                'Receiving payments from your mobile phone is now much faster. With AHL Pay CepPOS, accept contactless payments anywhere you want in Turkish Lira, Dollars, or Euros.',
            imagePath: 'assets/uploads/products/products/kurumsal_ceppos.png',
            title2: <><span>AHL Pay </span>CepPOS</>,
            childrens: [
                {
                    title: 'Fast and Easy Payment Solutions',
                    desc: 'Access all the features you need with a single click and receive payments instantly. Use your mobile device to provide a practical and fast payment experience for your customers.'
                },
                {
                    title: 'Location Independence',
                    desc: 'With CepPOS, carry out your transactions anywhere without losing time.'
                },
                {
                    title: 'Secure and Transparent Transactions',
                    desc: 'Every transaction made with CepPOS is securely and transparently recorded, ensuring your customers’ information is always protected.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
