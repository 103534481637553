// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text  }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="AndroidPOS Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay AndroidPOS',
            headerTitle: 'AndroidPOS',
            title: "AHL Pay Android POS",
            shortDescription:
                'Dijital çözümler sunarak her yerde hızlı ve güvenli ödeme al. Android POS ile işlemlerini kolaylaştır, hızlandır ve dijitalleştir. Satış, ödeme ve fatura işlemlerini birleştirerek daha etkin ve hızlı bir iş yönetim deneyimi sağla.',
            description:
                'VUK 507 ve 509 (Vergi Usul Kanunu) tebliğine uygun olarak geliştirilen AHL Pay Android POS ile işletmenin satışlarını artırma ve büyüme potansiyelini maksimize et.',
            imagePath: 'assets/uploads/products/products/kurumsal_androidpos.png',
            title2: <><span>AHL Pay </span>Android POS</>,
            childrens: [
                {
                    title: 'Güvenli ve Esnek Ödeme Seçenekleri',
                    desc: 'Kıymetli Madenleriniz ile yatırımlarınız güvende'
                },
                {
                    title: 'Etkili Ödeme İşlemleri',
                    desc: 'Android POS ile ödemelerini anında al. Kullanıcı dostu arayüzü sayesinde, işlemleri hızlıca tamamlayarak müşterilerine kesintisiz bir deneyim sunar.'
                },
                {
                    title: 'Hızlı ve Güvenli Ödeme Alma',
                    desc: 'Temassız özelliği sayesinde Android POS ile, kredi kartlarından, banka kartlarından veya ön ödemeli kartlardan güvenle ödeme al.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay AndroidPOS',
            headerTitle: 'AndroidPOS',
            title: "AHL Pay Android POS",
            shortDescription:
                'Offer digital solutions to receive fast and secure payments anywhere. Simplify, accelerate, and digitize your transactions with Android POS. By integrating sales, payment, and invoicing, achieve a more efficient and faster business management experience.',
            description:
                'Developed in compliance with VUK 507 and 509 (Tax Procedure Law), AHL Pay Android POS enables businesses to maximize sales growth and expansion potential.',
            imagePath: 'assets/uploads/products/products/kurumsal_androidpos.png',
            title2: <><span>AHL Pay </span>Android POS</>,
            childrens: [
                {
                    title: 'Secure and Flexible Payment Options',
                    desc: 'Secure your investments with Precious Metals'
                },
                {
                    title: 'Efficient Payment Processing',
                    desc: 'Receive payments instantly with Android POS. Its user-friendly interface ensures quick transaction completion and uninterrupted customer experience.'
                },
                {
                    title: 'Fast and Secure Payment Acceptance',
                    desc: 'With its contactless feature, securely receive payments from credit cards, debit cards, or prepaid cards using Android POS.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
