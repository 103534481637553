// @ts-nocheck
import { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import MainLayout from '../layouts/MainLayout';
import ProductApplicationModal from './modals/ProductApplication'

const _renderJSON = (setOpenModal) => {
    return {
        Productsnav: [
            {
                title: <><li active><a data-goto="altinpos" title="Altın Pos">Altın Pos</a></li>
                    <li><a data-goto="androidpos" title="Android Pos">Android Pos</a></li>
                    <li><a data-goto="sanalpos" title="Sanal Pos">Sanal Pos</a></li>
                    <li><a data-goto="ceppos" title="Cep Pos">Cep Pos</a></li>
                    <li><a data-goto="yazarkasapos" title="Yazar - Kasa Pos">Yazar - Kasa Pos</a></li></>,
                languageCode: 'tr'
            },
            {
                title: <>     <li active><a data-goto="altinpos" title="Altın Pos">Altın Pos</a></li>
                    <li><a data-goto="androidpos" title="Android Pos">Android Pos</a></li>
                    <li><a data-goto="sanalpos" title="Sanal Pos">Virtual Pos</a></li>
                    <li><a data-goto="ceppos" title="Cep Pos">Cep Pos</a></li>
                    <li><a data-goto="yazarkasapos" title="Yazar - Kasa Pos">Authorize Cash POS</a></li></>,
                languageCode: 'en'
            }
        ],
        corporateheader: [
            {
                title: <h1>AHL Pay ile yeni finansal<br />özgürlüğünü keşfet</h1>,
                languageCode: 'tr'
            },
            {
                title: <h1>Discover your new <br />financial freedom</h1>,
                languageCode: 'en'
            }
        ],
        corporateproduct1: [
            {
                title: <h3>"Kuyumculara Özel POS" ürünü ile Android POS üzerinden TL karşılığı Altın, Dolar, Euro alım-satım işlemleri gerçekleştirme imkanı sağlar.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3>Altın POS offers a "POS for Jewelers" solution, allowing transactions in gold, dollars, and euros via Android POS in exchange for Turkish Lira.</h3>,
                languageCode: 'en'
            }
        ],
        corporateproduct2: [
            {
                title: <h3>Dijital çözümler sunarak her yerde hızlı ve güvenli ödeme alabilirsiniz. Android POS ile işlemlerinizi kolaylaştırın, hızlandırın ve dijitalleştirin.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3>You can receive fast and secure payments anywhere with flexible and portable solutions. Simplify, faster, and digitize your processes with Android POS.</h3>,
                languageCode: 'en'
            }
        ],
        corporateproduct3: [
            {
                title: <h3>AHL Pay Sanal Pos, işletmenizin finansal işlemlerini sadeleştirirken, aynı zamanda hızlı, güvenli ve ekonomik bir çözüm sunar.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3> Virtual POS simplifies your business's financial transactions while also providing a fast, secure and economical solution.</h3>,
                languageCode: 'en'
            }
        ],
        corporateproduct4: [
            {
                title: <h3>NFC destekli telefonunuzdan ödeme almak artık çok daha hızlı ! AHL Pay Cep Pos ile dilediğiniz yerden Türk Lirası, Dolar ve Euro Para birimi seçenekleri ile temassız ödeme alabilirsiniz.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3>Receiving payments from your NFC-enabled phone is now much faster! With AHL Pay Cep POS, you can receive contactless payments with Turkish Lira, Dollar and Euro currency options from anywhere you want.</h3>,
                languageCode: 'en'
            }
        ],
        corporateproduct4: [
            {
                title: <h3>Cep telefonunuzdan ödeme almak artık çok daha hızlı ! AHL Pay Cep Pos ile dilediğiniz yerden Türk Lirası, Dolar ve Euro Para birimi seçenekleri ile temassız ödeme alabilirsiniz.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3>Receiving payments from your NFC-enabled phone is now much faster! With AHL Pay Cep POS, you can receive contactless payments with Turkish Lira, Dollar and Euro currency options from anywhere you want.</h3>,
                languageCode: 'en'
            }
        ],
        corporateproduct5: [
            {
                title: <h3>Pos Cihazı ve Yazar Kasayı birleştiren aynı zamanda satış bilgilerini doğrudan Gelir İdaresi Başkanlığı’na veri aktarımı yapabilen yeni nesil Yazar Kasa POS cihazıdır.</h3>,
                languageCode: 'tr'
            },
            {
                title: <h3>Maximizes your business's potential for increasing sales and growth.</h3>,
                languageCode: 'en'
            }
        ],
        PosTitle1: [
            {
                title: <h2>Sanal Pos</h2>,
                languageCode: 'tr'
            },
            {
                title: <h2>Virtual Pos</h2>,
                languageCode: 'en'
            }
        ],
        PosTitle2: [
            {
                title: <h2>Yazar Kasa Pos</h2>,
                languageCode: 'tr'
            },
            {
                title: <h2>Authorize Cash POS</h2>,
                languageCode: 'en'
            }
        ],
        redirect1: [
            {
                title: <h5>Sen de yeni finansal<br />özgürlüğünü keşfet!</h5>,
                languageCode: 'tr'
            },
            {
                title: <h5>Discover your new<br />financial freedom!</h5>,
                languageCode: 'en'
            }
        ],
        redirect2: [
            {
                title: <a onClick={() => setOpenModal(true)} modal-open="application" title="Başvur"><div>Başvur</div></a>,
                languageCode: 'tr'
            },
            {
                title: <a onClick={() => setOpenModal(true)} modal-open="application" title="Apply"><div>Apply</div></a>,
                languageCode: 'en'
            }
        ]

    }
}

const ProductCorporatePage = () => {

    return <MainLayout children={_render()} />
};

const _renderButton = (selectedProduct, language, setSelectedProductId, setOpenModal) => {

    if (language == 'tr') {
        return <a onClick={() => { setSelectedProductId(selectedProduct); setOpenModal(true); }} modal-open="application" title="Başvur"><div>Başvur</div></a>
    } else {
        return <a onClick={() => { setSelectedProductId(selectedProduct); setOpenModal(true); }} modal-open="application" title="Apply"><div>Apply</div></a>
    }
}

const _render = () => {
    const [openModal, setOpenModal] = useState(false);

    const PageJson = _renderJSON(setOpenModal);
    const [selectedProductId, setSelectedProductId] = useState(null);

    useEffect(() => {
        const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            const target = event.currentTarget;
            const goto = target.getAttribute('data-goto');
            if (goto) {
                document.querySelectorAll('[element=products-nav] ul li[active]').forEach(el => {
                    el.removeAttribute('active');
                });
                target.parentElement?.setAttribute('active', '');

                const scrollToElement = document.querySelector(`[element][data-goto="${goto}"]`);
                if (scrollToElement) {
                    window.scrollTo({
                        top: scrollToElement.getBoundingClientRect().top + window.scrollY - 180,
                        behavior: 'smooth'
                    });
                }
            }
        };

        document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
            el.addEventListener('click', handleNavClick);
        });

        return () => {
            document.querySelectorAll('[element=products-nav] ul li a').forEach(el => {
                el.removeEventListener('click', handleNavClick);
            });
        };
    }, []);

    const [activeTab, setActiveTab] = useState("fees");
    const { language } = useLanguage();
    return <main page="inside">

        <section element="products-nav">
            <div>
                <ul>
                    {PageJson.Productsnav.find(x => x.languageCode == language)?.title}
                </ul>
            </div>
        </section>

        <section element="corporate-header">
            <div>
                {PageJson.corporateheader.find(x => x.languageCode == language)?.title}
            </div>
        </section>

        <section element="corporate-product" element-direction="ltr" data-goto="altinpos">
            <div>
                <aside>
                    <h2>Altın Pos</h2>
                    {PageJson.corporateproduct1.find(x => x.languageCode == language)?.title}
                    {_renderButton("AltinPOS", language, setSelectedProductId, setOpenModal)}
                </aside>
                <picture><img src="/assets/uploads/products/products/kurumsal_altinpos.png" alt="Altın Pos" /></picture>
            </div>
        </section>

        <section element="corporate-product" element-direction="rtl" data-goto="androidpos">
            <div>
                <aside>
                    <h2>Android Pos</h2>
                    {PageJson.corporateproduct2.find(x => x.languageCode == language)?.title}
                    {_renderButton("AndroidPOS", language, setSelectedProductId, setOpenModal)}
                </aside>
                <picture><img src="/assets/uploads/products/products/kurumsal_androidpos.png" alt="Android Pos" /></picture>
            </div>
        </section>

        <section element="corporate-product" element-direction="ltr" data-goto="sanalpos">
            <div>
                <aside>
                    {PageJson.PosTitle1.find(x => x.languageCode == language)?.title}
                    {PageJson.corporateproduct3.find(x => x.languageCode == language)?.title}
                    {_renderButton("SanalPOS", language, setSelectedProductId, setOpenModal)}
                </aside>
                <picture><img src="/assets/uploads/products/products/sanalpos_odeme_pc.png" alt="Sanal Pos" /></picture>
            </div>
        </section>



        <section element="corporate-product" element-direction="rtl" data-goto="ceppos">
            <div>
                <aside>
                    <h2>Cep Pos</h2>
                    {PageJson.corporateproduct4.find(x => x.languageCode == language)?.title}
                    {_renderButton("CepPOS", language, setSelectedProductId, setOpenModal)}
                </aside>
                <picture><img src="/assets/uploads/products/products/kurumsal_ceppos.png" alt="Cep Pos" /></picture>
            </div>
        </section>

        <section element="corporate-product" element-direction="ltr" data-goto="yazarkasapos">
            <div>
                <aside>
                    {PageJson.PosTitle2.find(x => x.languageCode == language)?.title}
                    {PageJson.corporateproduct5.find(x => x.languageCode == language)?.title}
                    {_renderButton("OKCPOS", language, setSelectedProductId, setOpenModal)}
                </aside>
                <picture><img src="/assets/uploads/products/products/kurumsal_yazarkasapos.png" alt="Yazar - Kasa Pos" /></picture>
            </div>
        </section>

        <div element="redirect1">
            <div>
                {PageJson.redirect1.find(x => x.languageCode == language)?.title}
                {PageJson.redirect2.find(x => x.languageCode == language)?.title}
            </div>
        </div>

        <ProductApplicationModal openModal={openModal} setOpenModal={setOpenModal} selectedProductId={selectedProductId} />
    </main>;
}

export default ProductCorporatePage;