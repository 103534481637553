// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [
  {
    "id": 28,
    "title": "ARABİCA COFFEE HOUSE'ta %10 İndirim",
    "subTitle": "",
    "detail": "Arabica Coffee House'ta her yudumda AHL Pay ayrıcalığı sizinle! AHL Pay üyelerine özel, her siparişte %10 indirim fırsatı sizi bekliyor! İndirim kodları yalnızca 'Arabica Coffee House' uygulaması üzerinden yapılan alışverişlerde geçerlidir.\n\nKampanya koşulları:\n- Kampanya kodu 15.12.2024 tarihine kadar kullanılabilir.\n- Kampanya yalnızca Arabica Coffee House üzerinden yapılan alışverişlerde geçerlidir.\n- Tüm sıcak ve soğuk içecekler için geçerlidir.\n- Bu kampanya başka bir kampanya ile birleştirilemez.\n- AHL Pay ve Arabica Coffee House kampanya tarihi ve koşullarında değişiklik yapma hakkına sahiptir.\n\nİndirim kodu nasıl kullanılır?\n1. Arabica Coffee House uygulaması üzerinden sipariş verilmesi gerekmektedir.\n2. Uygulama içerisindeki 'Profilim' sekmesinde bulunan 'indirim kodu' alanını seçiniz.\n3. İndirim kodu ekle butonuna dokununuz ve açılan alana indirim kodunuzu giriniz.\n4. Uygula butonu ile indirimi uygulama içerisine tanımlayabilirsiniz.\n5. Eklediğiniz indirim kodu, indirim kodu sayfasında aktif olarak görünecektir. Siparişinizi geçerli kategorilerden ürünleri içerecek şekilde oluşturup sepet ekranında bulunan indirimi uygula butonu ile uygulayabilirsiniz.\n\nSize en yakın Arabica şubesini bulmak için mobil uygulamamızı kullanın:\nhttps://arabicacoffee.com.tr/app",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/campaigns/detail/arabica.jpeg"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(28);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
