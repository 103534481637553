// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const ProductPage = ({ productData, h5Text }) => {
    const {
        title,
        headerTitle,
        shortDescription,
        imagePath,
        description,
        title2,
        childrens
    } = productData;

    return (
        <>
            <NavBar />
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>
                            {description}
                        </blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage();
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    
    const productData = {
        tr: {
            id: 4,
            pageTitle: "AHL Pay'den bir ilk: ATOM Hesapta",
            headerTitle: 'ATOM',
            title: "AHL Pay'den bir ilk: ATOM Hesapta",
            shortDescription: 'ATOM Hesapta ile anlaşmalı kuyumcumlara teslim ettiğin altının, kuyumcuya teslim edildiği anda ilgili finans kurumundaki altın hesabına yatar.',
            description: (
                <>
                    Altınını fiziki saklamak yerine AHL Pay uygulamasındaki altın hesabında tutarak finansal piyasaların avantajlarından yararlanabilirsin. ATOM Hesapta uygulaması milyem avantajları içerir, birikiminden kayıp yaşamanı engeller. Sadece gram altın ve cumhuriyet altını gibi standart altın türleri değil, bilezik, yüzük, küpe, kolye gibi tüm kuyum ürünlerini anlaşmalı kuyumculara getir, ATOM Hesapta ile AHL Pay uygulamandaki altın hesabına sadece birkaç saniye içerisinde aktarılsın. Ayrıca birlikte çalıştığımız kuyumcularımızı <a href='assets/images/KuyumcuListemiz.pdf' style={{ color: 'blue' }} download>görmek için tıkla</a>.
                </>
            ),
            imagePath: 'assets/uploads/products/phones/ATOM.png',
            title2: <> <span>ATOM </span>Hesapta</>,
            childrens: [
                {
                    title: 'Anında Transfer',
                    desc: "Tüm kuyum ürünleri saniyeler içinde altın hesabında"
                },
                {
                    title: 'Avantajlı Kur',
                    desc: 'Altın-TL kuru ile Türk lirasına çevirme imkanı'
                },
                {
                    title: 'Güvenli İşlem',
                    desc: 'İşlemleriniz Ahlatcı Metal Rafineri Güvencesi ile koruma altında'
                }
            ]
        },
        en: {
            id: 4,
            pageTitle: "A First from AHL Pay: ATOM Account",
            headerTitle: 'ATOM',
            title: "A First from AHL Pay: ATOM Account",
            shortDescription: 'With the ATOM Account, the gold you deliver to our partner jewelers is instantly deposited into your gold account at the respective financial institution as soon as it is handed over.',
            description: (
                <>
                    Instead of physically storing your gold, you can keep it in your gold account through the AHL Pay app and take advantage of financial market benefits. The ATOM Account application includes millesimal advantages, preventing loss from your savings. Bring not only standard gold types like gram gold and Republic gold but also all jewelry products like bracelets, rings, earrings, and necklaces to our partner jewelers. With ATOM Account, they are transferred to your gold account in the AHL Pay app within seconds. Additionally, you can <a href='assets/images/KuyumcuListemiz.pdf' style={{ color: 'blue' }} download>click here</a> to view the list of our partner jewelers.
                </>
            ),
            imagePath: 'assets/uploads/products/phones/ATOM.png',
            title2: <> <span>ATOM </span>Account</>,
            childrens: [
                {
                    title: 'Instant Transfer',
                    desc: "All jewelry products are transferred to your gold account within seconds"
                },
                {
                    title: 'Advantageous Rate',
                    desc: 'Opportunity to convert gold to Turkish Lira with competitive exchange rates'
                },
                {
                    title: 'Secure Transaction',
                    desc: 'Your transactions are safeguarded with the assurance of Ahlatcı Metal Refinery'
                }
            ]
        }
    };

    const localizedProductData = productData[language] || productData['tr'];

    useEffect(() => {
        document.title = localizedProductData.pageTitle;
    }, [language]);

    return (
        <div>
            <AppHeader />
            <br /><br /><br /><br /><br /><br />
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
