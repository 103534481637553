// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div
                                        key={index}
                                        grid-item=""
                                        solution-open="demo1"
                                    >
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target='_blank'
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target='_blank'
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihine eriş
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            pageTitle: 'Fatura Öde',
            headerTitle: 'Fatura Öde',
            title: "Bireysel hesabınız ile hızlı ve güvenle Faturalarını Ödeyin!",
            shortDescription: 'AHL Pay Bireysel Hesap, fatura ödemelerini hızlı, güvenli ve sorunsuz bir şekilde gerçekleştirebileceğin, mükemmel bir çözüm sunar.',
            description: "AHL Pay Bireysel Hesap, finansal işlemlerinin karmaşıklığını azaltır ve zamandan tasarruf etmeni sağlar. Ayrıca, faturalarını www.benimfaturam.com.tr üzerinden tüm banka kartları ve anlaşmalı kredi kartları ile taksitli olarak ödeyebilirsin.",
            imagePath: 'assets/uploads/products/phones/faturaode_phone.svg',
            title2: <>AHL Pay ile <span>Fatura Öde</span></>,
            childrens: [
                {
                    title: 'Faturalarını Sorgula',
                    desc: 'AHL Pay ile faturalarını hızlıca sorgula'
                },
                {
                    title: 'Hızlı ve Güvenli',
                    desc: 'Fatura işlemlerini güvenli ve sorunsuz gerçekleştir'
                },
                {
                    title: 'Aynı Anda Birçok Faturayı Öde',
                    desc: 'Faturalarını AHL Pay ile tek bir kanaldan anında öde'
                }
            ]
        },
        en: {
            pageTitle: 'Pay Bills',
            headerTitle: 'Pay Bills',
            title: "Quickly and securely pay your bills with your personal account!",
            shortDescription: 'AHL Pay Personal Account offers an excellent solution for quick, secure, and hassle-free bill payments.',
            description: "AHL Pay Personal Account simplifies financial transactions and saves you time. Additionally, you can pay your bills in installments with all bank cards and contracted credit cards on www.benimfaturam.com.tr.",
            imagePath: 'assets/uploads/products/phones/faturaode_phone.svg',
            title2: <>Pay Bills with <span>AHL Pay</span></>,
            childrens: [
                {
                    title: 'Check Your Bills',
                    desc: 'Quickly check your bills with AHL Pay'
                },
                {
                    title: 'Fast and Secure',
                    desc: 'Perform bill transactions securely and without hassle'
                },
                {
                    title: 'Pay Multiple Bills at Once',
                    desc: 'Instantly pay multiple bills through AHL Pay from a single channel'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br /><br /><br /><br /><br /><br />
            {/* ProductPage bileşeni, localize edilmiş veri ile render ediliyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
