import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const KurumsalNavBar = () => {
  const { language } = useLanguage();


  const navItems = {
    tr: [
      { path: '/kurumsal-urunler/kart', label: 'Kurumsal Kart' },
      { path: '/kurumsal-urunler/androidpos', label: 'Android POS' },
      { path: '/kurumsal-urunler/altinpos', label: 'Altın POS' },
      { path: '/kurumsal-urunler/ceppos', label: 'Cep POS' },
      { path: '/kurumsal-urunler/sanalpos', label: 'Sanal POS' },
      { path: '/kurumsal-urunler/kurumsal-hesap', label: 'Kurumsal Hesap' },
      { path: '/kurumsal-urunler/okcpos', label: 'Yazarkasa' },
    ],
    en: [
      { path: '/kurumsal-urunler/kart', label: 'Corporate Card' },
      { path: '/kurumsal-urunler/androidpos', label: 'AndroidPOS' },
      { path: '/kurumsal-urunler/altinpos', label: 'AltınPOS' },
      { path: '/kurumsal-urunler/ceppos', label: 'CepPOS' },
      { path: '/kurumsal-urunler/sanalpos', label: 'SanalPOS' },
      { path: '/kurumsal-urunler/kurumsal-hesap', label: 'Corporate Account' },
      { path: '/kurumsal-urunler/okcpos', label: 'Cash Register' },
    ],
  };

  return (
    <nav style={styles.navbar}>
      <ul style={styles.navLinks}>
        {navItems[language]?.map((item, index) => (
          <li key={index} style={styles.navItem}>
            <Link to={item.path} style={styles.navLink}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const styles = {
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    WebkitOverflowScrolling: 'touch',
  },
  navLinks: {
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    overflowX: 'auto', overflowY: 'hidden',
  },
  navItem: {
    marginLeft: '25px',
    flexShrink: 0,
  },
  navLink: {
    textDecoration: 'none',
    color: '#6c757d',
    fontSize: '16px',
    fontWeight: '350',
    lineHeight: '1.5',
    padding: '5px 0',
  },
};

export default KurumsalNavBar;
