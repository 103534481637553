import React, { useEffect, useState } from 'react';
import './xxxx.css'

const LiveChatComponent = () => {
  const kurumsal = {
    title: "AHL Pay Kurumsal Canlı Destek",
    businessChannelID: 9,
    color: "#04aa3c",
  };

  const bireysel = {
    title: "AHL Pay Bireysel Canlı Destek",
    businessChannelID: 10,
    color: "#042bb7",
  };

  const addN4bChatScript = () => {
    const script = document.createElement('script');
    script.id = 'n4bChatScript';
    script.src = 'https://livechatwidget-ahlpay.next4biz.net/scripts/n4bChat.init.js?v=1.0.0';
    console.log('script', script)
    document.body.appendChild(script);

    script.onload = () => {
      const chatContainer = document.getElementById('next4biz_liveChat_Container');
      console.log('chatContainer', chatContainer.style.height)
      if (chatContainer) {
        chatContainer.style.bottom = '60px';
      }
    }
  };

  const chatContainerElement = document.getElementById('next4biz_liveChat_Container');
  const Next4bizChat = document.getElementById('Next4bizChat-popup');

  console.log('Next4bizChat', Next4bizChat)
  if (chatContainerElement) {
    chatContainerElement.style.bottom = "43px";
  }

  const startN4bChat = (obj) => {
    document.addEventListener('DOMContentLoaded', () => {
      const chatPopup = document.querySelector('#Next4bizChat-popup');
      if (chatPopup) {
        chatPopup.style.backgroundColor = '#f0f0f0';
        chatPopup.style.borderRadius = '10px';
        chatPopup.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
      }
    });

    if (JSON.parse(localStorage.getItem('n4bChatStart'))) {
      const localStorageBusinessChannelID = JSON.parse(localStorage.getItem('n4bChat')).businessChannelID;
      if (localStorageBusinessChannelID === obj.businessChannelID) {
        return;
      } else {
        const iframe = document.getElementById('next4biz_liveChat_Frame');
        console.log('iframe.contentWindow', iframe.contentWindow)
        if (iframe) {
          iframe.contentWindow.postMessage({ showCloseModal: true }, '*');
          return;
        }
      }
    }

    const scriptElement = document.getElementById('n4bChatScript');
    const chatContainerElement = document.getElementById('next4biz_liveChat_Container');
    console.log('scriptElement', scriptElement)
    if (scriptElement) {
      document.body.removeChild(scriptElement);
    }

    if (chatContainerElement) {
      chatContainerElement.remove();
    }

    window.n4bChat = { ...window.n4bChat, ...obj };
    addN4bChatScript();

    // Seçimden sonra menüyü her durumda kapatr
    setIsPanelVisible(false);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('n4bChatStart'))) {
      addN4bChatScript();
      window.n4bChat = JSON.parse(localStorage.getItem('n4bChat'));
    }

    const handleMessage = (e) => {
      if (e.data.status === 'n4bChat' && e.data.hasOwnProperty('n4bChatStart')) {
        localStorage.setItem('n4bChatStart', e.data.n4bChatStart);
        localStorage.setItem('n4bChat', JSON.stringify(window.n4bChat));
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const [isPanelVisible, setIsPanelVisible] = useState(false);

  const toggleSupportPanel = () => {
    const chatContainer = document.getElementById('next4biz_liveChat_Container');

    setIsPanelVisible((prev) => {
      console.log('prev', prev)
      prev = !prev;
      if (prev) {
        const chatContainer = document.getElementById('next4biz_liveChat_Container');
        if (chatContainer) {
          chatContainer.style.bottom = '170px';
        }
      }
      return prev;
    });
  };

  return (
    <div>
      <div className="live-support-container">
        <button className="live-support-button" onClick={toggleSupportPanel}>
          Canlı Destek
        </button>

        {isPanelVisible && (
          <div className="support-panel">
            <button className="support-option" onClick={() => {
              startN4bChat(bireysel);
            }} style={{ backgroundColor: '#002abe' }}>
              Bireysel
            </button>
            <button className="support-option" onClick={() => {
              startN4bChat(kurumsal);
            }} style={{ backgroundColor: '#05aa3c' }}>
              Kurumsal
            </button>
          </div>
        )}
      </div>

      <style jsx>{`

        .live-support-container {
          position: fixed;
          bottom: 10px;
          right: 20px;
          text-align: center;
          z-index: 1000;
        }

        .live-support-button {
          background-color: #05aa3c; /* Green color for Canlı Destek button */
          color: white;
          padding: 10px 25px;
          border: none;
          border-radius: 17px;
          font-size: 16px;
          cursor: pointer;
        }

        .support-panel {
          display: block;
          margin-top: 10px;
          background-color: #f3f3f3;
          padding: 10px;
          border-radius: 17px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }

        .support-option {
          display: block;
          width: 100%;
          margin: 5px 0;
          padding: 10px;
          border: none;
          border-radius: 17px;
          color: white;
          cursor: pointer;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default LiveChatComponent;

window.n4bChat = {
  n4bChat: true,
  title: "AHL Pay Canlı Destek",
  welcomeMessage: "Merhaba, size daha iyi hizmet sunabilmemiz için aşağıdaki bilgileri doldurmanızı rica ederiz.",
  color: "#5da283",
  icon: null, // 32px
  siteUrl: "https://www.ahlpay.com.tr/",
  langID: 1,
  companyID: 7,
  issueContactMethodID: null,
  issueCategoryID: null,
  issueTypeID: null,
  iframeSrc: "https://livechatwidget-ahlpay.next4biz.net/widget",
  copyRight: "Powered by Next4biz CSM",
  initialLayer: true,
};
