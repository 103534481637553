// @ts-nocheck

import React, { useEffect } from "react";
import AppHeader from "../../../components/AppHeader";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import { useLanguage } from "../../../contexts/LanguageContext";

const productData = {
  pageTitle: {
    tr: "AHL Card",
    en: "AHL Card",
  },
  headerTitle: {
    tr: "AHL Card",
    en: "AHL Card",
  },
  title: {
    tr: "AHL Card ile Finansın Özgür Haliyle Tanış!",
    en: "Meet the Free State of Finance with AHL Card!",
  },
  shortDescription: {
    tr: "AHL Card ile harcamalarını etkili bir şekilde takip edebilir ve hesabına yüklediğin tutar kadar harcama gerçekleştirebilirsin. AHL Card’ı yurt içindeki alişverişlerinin yanı sıra internet alişverişlerinde de kullanabilirsin. Bu sayede bütçeni daha iyi yönetebilir ve harcamalarını her zaman kontrol altında tutabilirsin.",
    en: "With AHL Card, you can effectively track your expenses and spend only the amount you load onto your account. You can use AHL Card for domestic purchases as well as online shopping. This way, you can manage your budget better and always keep your expenses under control.",
  },
  products: [
    {
      title: {
        tr: "Her şey kontrolün altında",
        en: "Everything is under control",
      },
      desc: {
        tr: "AHL Card ile her şey elinizde ve kontrol sizde finans harcamalarınızı güvenle şekillendirebilirsin.",
        en: "With AHL Card, everything is in your hands and under your control; you can shape your financial spending securely.",
      },
    },
    {
      title: {
        tr: "Dilediğin gibi harca bütçeni aşma",
        en: "Spend as you wish without exceeding your budget",
      },
      desc: {
        tr: "Yurt içi ve online harcamalarını dile gibi harcayabilir bütçeni hiçbir zaman aşmazsın.",
        en: "You can spend freely on domestic and online purchases without ever exceeding your budget.",
      },
    },
    {
      title: {
        tr: "Oluştur ve Harcadıkça Cashback kazan",
        en: "Create and earn Cashback as you spend",
      },
      desc: {
        tr: "AHL Card oluşturarak 200₺ nakit kazan, her yaptığın harcamada Cashback kazanma fırsatı yakala.",
        en: "Create an AHL Card and earn 200₺ in cash, with the opportunity to earn Cashback on every purchase.",
      },
    },
  ],
  posterTitle: {
    tr: "Senin AHL Card'ın hangisi?",
    en: "Which AHL Card is yours?",
  },
  posterDescription: {
    tr: "TROY logolu AHL Card'lar tüm avantajlarıyla seni bekliyor. Uygulamaya gir, kullanıcı oluştur, AHL Card tercihini yap, hemen adresine gelsin.",
    en: "TROY-branded AHL Cards are waiting for you with all their advantages. Enter the app, create a user, choose your AHL Card, and have it delivered to your address immediately.",
  },
  banner: {
    tr: "Finansal özgürlüğünü şimdi keşfet",
    en: "Discover financial freedom now",
  },
  campaignDetails: {
    tr: {
      title: "Kampanya Detayı:",
      description:
        "AHL Card’ınızı oluşturarak 200₺ nakit hediye fırsatını yakalayın! Uygulama üzerinden ilk defa AHL Card'ı aktif ettiğinizde, hesabına yatacak olan 200₺ nakit hediyeni anında kullanabilirsin.",
      note: "(Dark ya da Moonlight farketmeksizin kampanya sadece ilk kart siparişinde geçerlidir.)",
    },
    en: {
      title: "Campaign Details:",
      description:
        "Get a 200₺ cash gift by creating your AHL Card! When you activate your AHL Card for the first time through the app, you can instantly use your 200₺ cash gift in your account.",
      note: "(The campaign is valid only for the first card order, regardless of Dark or Moonlight versions.)",
    },
  },
};

const CardPage = () => {
  const { language } = useLanguage();

  useEffect(() => {
    document.title = productData.pageTitle[language];
  }, [language]);

  return (
    <>
      <NavBar />
      <section element="individual-header">
        <div>
          <div>
            <h1>{productData.title[language]}</h1>
            <br />
            <span>{productData.shortDescription[language]}</span>
            <div>
              <grid grid="individual-products">
                {productData.products.map((product, index) => (
                  <div key={index} grid-item="">
                    <div>
                      <h3>{product.title[language]}</h3>
                      <blockquote>{product.desc[language]}</blockquote>
                    </div>
                  </div>
                ))}
              </grid>
            </div>
          </div>
          <div>
            <img
              src="../assets/uploads/products/icons/card.svg"
              alt="AHL Card"
            />
          </div>
        </div>
      </section>

      <section element="individual-poster">
        <div>
          <article>
            <h2>{productData.posterTitle[language]}</h2>
            <blockquote>{productData.posterDescription[language]}</blockquote>
          </article>
          <picture>
            <img src="/assets/images/ahlcard-dark.png" alt="AHL Card Dark" />
            <img
              src="/assets/images/ahlcard-moonlight.png"
              alt="AHL Card Moonlight"
            />
          </picture>
          <b>{productData.campaignDetails[language].title}</b>
          <br />
          <big>
            <i>{productData.campaignDetails[language].description}</i>
          </big>
          <small>{productData.campaignDetails[language].note}</small>
        </div>
      </section>
      <div element="individual-banner">
        <div>
          <aside>
            <h5>{productData.banner[language]}</h5>
            <div aria-label="apps">
              <ul>
                <li>
                  <a
                    href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                    target="_blank"
                    title="AHL Pay: IOS Uygulaması"
                  >
                    <img
                      src="/assets/images/app-ios-lg.png"
                      alt="AHL Pay: IOS App"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                    target="_blank"
                    title="AHL Pay: Android Uygulaması"
                  >
                    <img
                      src="/assets/images/app-android-lg.png"
                      alt="AHL Pay: Android App"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </aside>
          <picture>
            <img
              src="/assets/uploads/products/banners/banner.png"
              alt={productData.banner[language]}
            />
          </picture>
        </div>
      </div>
    </>
  );
};

const App = () => {
  return (
    <div>
      <AppHeader />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <CardPage />
      <Footer />
    </div>
  );
};

export default App;
