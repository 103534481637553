// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Güvence Bedeli Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihine eriş
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            pageTitle: 'Güvence Bedeli',
            headerTitle: 'Güvence Bedeli',
            title: "Güvence Bedel İadesi Hesabınızda",
            shortDescription:
                'Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin.',
            description:
                "Enerya Doğalgaz, Çorum Doğalgaz, Kargaz Doğalgaz, Sürmeli Doğalgaz, Marmargaz Yalova ve Marmaragaz Çorlu aboneliğini sonlandırdığında, ödediğin güvence bedelini hızlı ve güvenli bir şekilde AHL Pay aracılığıyla geri alabilirsin. Güvence Bedeli İadesi Süreci: En yakın şubeye giderek abonelik sözleşmenin feshi için başvur. Cep telefonuna AHL Pay uygulamasını indir, kolayca hesap oluştur ve hesabını Onaylı statüne getir. Onaylı müşteri olduktan sonra IBAN bilgilerini gişe görevlisi ile paylaş. İade işlemi tamamlandığında, AHL Pay uygulamamız üzerinden anında bildirim al, tutar hesabına yansısın.",
            imagePath: 'assets/uploads/products/phones/iban_phone.svg',
            title2: (
                <>
                    <span>Güvence Bedel </span>İadesi
                </>
            ),
            childrens: [
                {
                    title: 'Hızlı ve Kolay !',
                    desc: 'AHL Pay hesabına hızlı ve kolay iade'
                },
                {
                    title: 'Birden Fazla Kurum',
                    desc: 'Dilediğin kurumun güvence bedel iadesini AHL Pay üzerinden alabilirsin'
                },
                {
                    title: 'Bedel İaden Güvende',
                    desc: 'Bedel iadeni AHL Pay aracılığıyla güvenle geri al'
                }
            ]
        },
        en: {
            pageTitle: 'Security Deposit',
            headerTitle: 'Security Deposit',
            title: "Your Security Deposit Refund is in Your Account",
            shortDescription:
                'When you terminate your subscription with Enerya Gas, Çorum Gas, Kargaz Gas, Sürmeli Gas, Marmaragaz Yalova, or Marmaragaz Çorlu, you can quickly and securely get your security deposit back via AHL Pay.',
            description:
                "When you terminate your subscription with Enerya Gas, Çorum Gas, Kargaz Gas, Sürmeli Gas, Marmaragaz Yalova, or Marmaragaz Çorlu, you can quickly and securely get your security deposit back via AHL Pay. Security Deposit Refund Process: Visit the nearest branch for subscription termination. Download the AHL Pay app on your mobile phone, create an account easily, and upgrade it to Verified status. After becoming a verified customer, share your IBAN details with the teller. Once the refund process is completed, you will instantly receive a notification via our AHL Pay app, and the amount will be credited to your account.",
            imagePath: 'assets/uploads/products/phones/iban_phone.svg',
            title2: (
                <>
                    <span>Security Deposit </span>Refund
                </>
            ),
            childrens: [
                {
                    title: 'Fast and Easy!',
                    desc: 'Quick and easy refund to your AHL Pay account'
                },
                {
                    title: 'Multiple Institutions',
                    desc: 'Receive refunds from your desired institution via AHL Pay'
                },
                {
                    title: 'Secure Refunds',
                    desc: 'Safely get your refunds via AHL Pay'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
