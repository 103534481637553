// @ts-nocheck
import React, { useState } from 'react';
import { useLanguage } from '../../../../contexts/LanguageContext';

const pageJSON = {
  headerTitle: [
    {
      title: 'Başvuru formu',
      languageCode: 'tr'
    },
    {
      title: 'Application form',
      languageCode: 'en'
    }
  ],
  shortDescription: [
    {
      title: <h5>Başvuru için <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> adresinden özgeçmişinizi iletebilirsiniz.</h5>,
      languageCode: 'tr'
    },
    {
      title: <h5>You can submit your resume to <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> for the application.</h5>,
      languageCode: 'en'
    }
  ],
  firstAndLastName: [
    {
      title: 'Adınız soyadınız',
      languageCode: 'tr'
    },
    {
      title: 'Full Name',
      languageCode: 'en'
    }
  ],
  phoneNumber: [
    {
      title: 'Telefon Numaranız',
      languageCode: 'tr'
    },
    {
      title: 'Phone Number',
      languageCode: 'en'
    }
  ],
  email: [
    {
      title: 'E-posta adresiniz',
      languageCode: 'tr'
    },
    {
      title: 'Email',
      languageCode: 'en'
    }
  ],
  note: [
    {
      title: 'Başvuru Notu',
      languageCode: 'tr'
    },
    {
      title: 'Application Note',
      languageCode: 'en'
    }
  ],
  dropCV: [
    {
      title: "CV'inizi sürükleyebilir  veya seçebilirsiniz.",
      languageCode: 'tr'
    },
    {
      title: 'Drop your CV here',
      languageCode: 'en'
    }
  ],
  send: [
    {
      title: "Gönder",
      languageCode: 'tr'
    },
    {
      title: 'Send',
      languageCode: 'en'
    }
  ],
  selectedFile: [
    {
      title: "Seçilen dosya",
      languageCode: 'tr'
    },
    {
      title: 'Selected file',
      languageCode: 'en'
    }
  ],
  success: [
    {
      title: "CV'niz başvurunuzla birlikte bizlere ulaştı. Başvurunuzun uygunluk kriterlerine uygun olduğu durumda sizinle iletişime geçeceğiz.",
      languageCode: 'tr'
    },
    {
      title: 'Your resume has been received along with your application. If your application meets the eligibility criteria, we will get in touch with you.',
      languageCode: 'en'
    }
  ]
};

const ApplicationModal = ({ openModal, setOpenModal }) => {

  const { language } = useLanguage();

  const [isWorking, setIsWorking] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const [fileName, setFileName] = useState('');
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    careerInfo: '',
    file: null,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFormData({ ...formData, file });
    } else {
      setFileName('');
      setFormData({ ...formData, file: null });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {

    const baseUrl = process.env.REACT_APP_API_ADDRESS;

    e.preventDefault();
    setIsWorking(true);
    const data = new FormData();
    data.append('CVFullName', formData.fullName);
    data.append('CVPhoneNumber', formData.phoneNumber);
    data.append('CVEmail', formData.email);
    data.append('CVFile', formData.file);
    data.append('CareerInfo', formData.careerInfo);

    try {
      const response = await fetch(baseUrl + '/Communication/SendCVAsEmail', {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'X-Requested-With': 'XMLHttpRequest',
          'sec-ch-ua': '"Not)A;Brand";v="99", "Google Chrome";v="127", "Chromium";v="127"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"Windows"',
        },
        body: data,
      });

      setIsWorking(false);
      if (response.ok) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    } catch (error) {
      setIsSuccess(false);
      setIsWorking(false);
    }
  };

  return (
    <div modal="career" style={{
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%"
    }}
      status={`${!openModal ? "hide" : "show"}`}
    >
      <div modal-content="">

        <span onClick={() => setOpenModal(false)} modal-close=""><i icon="mat-symbol xl">close</i></span>

        <h4>{pageJSON.headerTitle.find(x => x.languageCode == language)?.title}</h4>

        {isSuccess && (
          <div modal-result="">
            <div result="success">
              <i icon="mat-round md">check_circle_outline</i>
              <span>Mesajınız gönderilmiştir.</span>
            </div>
          </div>
        )}
        {isSuccess === false && (
          <div modal-result="">
            <div result="fail">
              <i icon="mat-round md">info_outline</i>
              <span>Mesajınız gönderilemedi, tekrar deneyiniz.</span>
            </div>
          </div>
        )}

        {pageJSON.shortDescription.find(x => x.languageCode == language)?.title}

        <form form="career" onSubmit={handleSubmit}>

          <label form-item="">
            <span>{pageJSON.firstAndLastName.find(x => x.languageCode == language)?.title}</span>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </label>

          <label form-item="mt-2">
            <span>{pageJSON.phoneNumber.find(x => x.languageCode == language)?.title}</span>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="0 (500) 000 00 00"
              required
            />
          </label>

          <label form-item="mt-2">
            <span>{pageJSON.email.find(x => x.languageCode == language)?.title}</span>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>

          <label form-item="mt-2">
            <span>{pageJSON.note.find(x => x.languageCode == language)?.title}</span>
            <textarea
              name="careerInfo"
              value={formData.careerInfo}
              onChange={handleChange}
              rows="6"
              required
            ></textarea>
          </label>

          <label form-item="file mt-2" htmlFor="files">
            <input
              type="file"
              id="files"
              accept=".doc,.docx,.pdf,.png,.jpg"
              onChange={handleFileChange}
              required
            />
            <span>
              <img src="/assets/images/icon-upload.png" alt="Upload Icon" />
              <cite></cite>
              <small>{pageJSON.dropCV.find(x => x.languageCode == language)?.title}</small>
            </span>
          </label>

          {fileName && (
            <p style={{ marginTop: '10px', fontWeight: 'bold' }}>{pageJSON.selectedFile.find(x => x.languageCode == language)?.title}: {fileName}</p>
          )}

          <label form-button="mt-2">
            <button className={isWorking ? 'button-disabled' : ''} disabled={isWorking ? 'true' : undefined} type="submit">{pageJSON.send.find(x => x.languageCode == language)?.title}</button>
          </label>

        </form>

      </div>
      <div modal-backdrop="" style={{ background: 'rgba(var(0 0 0) / 0%)' }}></div>
    </div >
  )
}

export default ApplicationModal;
