// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const campaigns = [
  {
    "id": 8,
    "title": "* 28.09.2024 GS - KAS Maçı Talihlileri",
    "subTitle": "",
    "detail": "Galatasaray – Kasımpaşa Trendyol Süper Lig Maç Bileti Talihlileri:\n\nASİL \n1- MURAT AYDIN\n2- SERKAN YANIKKOL\n3- FURKAN KIYAKKAS\n4- GÖKHAN YÜREKLİ\n5- FAHRETTİN SAHAN ALKAYA\n6- ESMA ÖZSOBACI\n7- SAKİR ŞAHİN\n8- MAŞALLAH AYDOĞDU\n9- HALİL CAN HATİŞ\n10- METEHAN AKBULUT\n\nYEDEK \n1- HAKAN KOCABAY\n2- KAMİL ÇAPLAN\n3- RONİ EKMEN\n4- HARUN BULUT\n5- NECATİ ŞANLI\n6- HACER FEYZA YEŞİLDAL\n7- ŞAFAK SARIKAYA\n8- OKAN ÇAM\n9- OKAN MALKOÇ\n10- TOLGA SAYGILI\n11- BARAN ARSLAN\n12- EMRE ÖZKAN",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/products/icons/gskas.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(8);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.imagePath} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
