// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const PageJSON = {
    title: [
        {
            title: 'Logolarımız',
            languageCode: 'tr',
        },
        {
            title: 'Logo',
            languageCode: 'en',
        }
    ],
    logoUseGuide: [
        {
            title: 'Detaylı bilgi ve yönergeler için<br />rehberi edinin',
            languageCode: 'tr',
        },
        {
            title: 'For detailed information and instructions<br />sign the guide',
            languageCode: 'en',
        }
    ],
    downloadButton: [
        {
            title: 'İndir',
            languageCode: 'tr',
        },
        {
            title: 'Download',
            languageCode: 'en',
        }
    ],
    priorityUsage: [
        {
            title: 'Öncelikli kullanım',
            languageCode: 'tr',
        },
        {
            title: 'Priority usage',
            languageCode: 'en',
        }
    ],
    alternativeUsage: [
        {
            title: 'Alternatif kullanım',
            languageCode: 'tr',
        },
        {
            title: 'Alternative usage',
            languageCode: 'en',
        }
    ],
}

const AboutLogos = () => {

    const { language } = useLanguage();

    const childrens = <main page="inside">
         <Helmet>
                <title>Logolarımız</title>
            </Helmet>

        <section element="about-logos">

            <h1>{PageJSON.title.find(x => x.languageCode == language)?.title}</h1>

            <div>

                <grid grid="about-logos">
                    <div grid-item="">
                        <picture><img src="/assets/uploads/about-logos/logo.svg" alt="Öncelikli kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.priorityUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture><img src="/assets/uploads/about-logos/logo_motto.svg" alt="Öncelikli kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.priorityUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_motto.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_motto.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture><img src="/assets/uploads/about-logos/logo_vertical.svg" alt="Alternatif kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.alternativeUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_vertical.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_vertical.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture><img src="/assets/uploads/about-logos/logo_motto_alternative.svg" alt="Alternatif kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.alternativeUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_motto_alternative.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_motto_alternative.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture><img src="/assets/uploads/about-logos/logo_mark.svg" alt="Logomark" style={{ height: '216px' }} /></picture>
                        <div>
                            <h3>Logo mark</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_mark.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_mark.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture style={{ background: 'linear-gradient(90deg, #05aa3c, #002abe)' }}><img src="/assets/uploads/about-logos/logo_white.svg" alt="Öncelikli kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.priorityUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_white.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_white.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture style={{ background: 'linear-gradient(90deg, #05aa3c, #002abe)' }}><img src="/assets/uploads/about-logos/logo_motto_white.svg" alt="Öncelikli kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.priorityUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_motto_white.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_motto_white.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture style={{ background: 'linear-gradient(90deg, #05aa3c, #002abe)' }}><img src="/assets/uploads/about-logos/logo_white_vertical.svg" alt="Alternatif kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.alternativeUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_white_vertical.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_white_vertical.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture style={{ background: 'linear-gradient(90deg, #05aa3c, #002abe)' }}><img src="/assets/uploads/about-logos/logo_motto_alternative_white.svg" alt="Alternatif kullanım" /></picture>
                        <div>
                            <h3>{PageJSON.alternativeUsage.find(x => x.languageCode == language)?.title}</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_motto_alternative_white.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_motto_alternative_white.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>
                    <div grid-item="">
                        <picture style={{ background: 'linear-gradient(90deg, #05aa3c, #002abe)' }}><img src="/assets/uploads/about-logos/logo_mark_white.svg" alt="Logomark" /></picture>
                        <div>
                            <h3>Logo mark</h3>
                            <ul>
                                <li><a href="/assets/uploads/about-logos/logo_mark_white.svg" download><img src="../assets/images/icon-svg.png" alt="SVG" />SVG</a></li>
                                <li><a href="/assets/uploads/about-logos/logo_mark_white.png" download><img src="../assets/images/icon-png.png" alt="PNG" />PNG</a></li>
                            </ul>
                        </div>
                    </div>

                </grid>

            </div>

        </section>

        <div element="redirect1">
            <div>
                <h5 dangerouslySetInnerHTML={{
                    __html: PageJSON.logoUseGuide.find(x => x.languageCode == language)?.title
                }} ></h5>
                <a href="/assets/uploads/about-logos/logo_kullanim_rehberi.pdf" download title={PageJSON.downloadButton.find(x => x.languageCode == language)?.title}>{PageJSON.downloadButton.find(x => x.languageCode == language)?.title}</a>
            </div>
        </div>

    </main>




    return <MainLayout children={childrens} />
};


export default AboutLogos;