// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="SanalPOS Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay SanalPOS',
            headerTitle: 'SanalPOS',
            title: "AHL Pay SanalPOS",
            shortDescription:
                'AHL Pay Sanal Pos, işletmenin finansal işlemlerini sadeleştirirken, aynı zamanda hızlı, güvenli ve ekonomik bir çözüm sunar. Kullanıcılar, tek çekim ve taksit imkanları ile tüm işlemlerini tek bir panel üzerinden takip edebilir, Masterpass entegrasyonu ile tek bir tıkla kayıtlı kartlarını ödeme ekranında listeleyebilir.',
            description:
                'Sanal POS, işletmene online ödeme imkanı sunar. Kullanıcı dostu arayüzü sayesinde, ödemelerini kolayca takip et. Güvenli ve hızlı işlemlerle müşterilerine keyifli bir alışveriş deneyimi yaşat.',
            imagePath: 'assets/uploads/products/products/sanalpos_odeme_pc.png',
            title2: <><span>AHL Pay </span>Sanal POS</>,
            childrens: [
                {
                    title: 'Anında Ödeme Almanın Kolay Yolu',
                    desc: 'Sanal POS ile ödemelerini dakikalar içinde al, düşük işlem ücretleri ile karlılığını artır.'
                },
                {
                    title: 'Güvenli Ödeme Seçenekleri',
                    desc: 'Gelişmiş güvenlik sistemleri ile her işlemde veri güvenliğini korur.'
                },
                {
                    title: 'Kullanıcı Dostu Arayüz',
                    desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay SanalPOS',
            headerTitle: 'SanalPOS',
            title: "AHL Pay SanalPOS",
            shortDescription:
                'AHL Pay Virtual POS streamlines your business financial transactions while offering a fast, secure, and cost-effective solution. Users can monitor all their transactions with single and installment payment options through one panel and list their registered cards on the payment screen with a single click via Masterpass integration.',
            description:
                'Virtual POS offers your business the ability to accept online payments. Thanks to its user-friendly interface, you can easily track your payments. Provide your customers with a pleasant shopping experience with secure and fast transactions.',
            imagePath: 'assets/uploads/products/products/sanalpos_odeme_pc.png',
            title2: <><span>AHL Pay </span>Virtual POS</>,
            childrens: [
                {
                    title: 'The Easy Way to Receive Instant Payments',
                    desc: 'Receive your payments within minutes with Virtual POS and increase your profitability with low transaction fees.'
                },
                {
                    title: 'Secure Payment Options',
                    desc: 'Protect data security with advanced security systems for every transaction.'
                },
                {
                    title: 'User-Friendly Interface',
                    desc: 'Grow your business quickly with easy setup and management.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
