// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [
  {
    "id": 15,
    "title": "Galatasaray-Samsunspor Maç Bileti Kazananları",
    "subTitle": "",
    "detail": "Galatasaray – Samsunspor Maçı Bileti Talihlileri:\n\nASİL \n1- Kadir Dursun\n2- Mustafa Durmuşbaş\n3- Yağmur Eren\n4- Hasan Erğun\n5- Abdullah Aziz Altuncu\n6- Hüseyin Umut Kızıl\n7- Fatih Akman\n8- Hayri Can Abacı\n9- Yasin Eroğlu\n10- Gökhan Yürekli\n\nYEDEK\n1- Muhammet Kara\n2- Emre Koç\n3- Arda Salih Civan\n4- Kübra Ceyda Koç\n5- Okan Arslan\n6- Serdar Hacırecepoğlu\n7- Armağan Şenel\n8- Nail Özdemir\n9- Yavuz Kuş\n10- Batuhan Koca\n11- Arda Şahin\n12- Bayram Yılmaz\n13- Oktay Çimen\n14- Emrah Ünlü\n15- Ramazan Cafer Ceylan",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/campaigns/detail/gs-sam.png"
  },
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(15);
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            
            <ul>
          <KampanyaNavBar />

              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
            
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  
                  <article>
                    
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                    
                  </article>
                  
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
