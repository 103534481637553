// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [
  {
    "id": 13,
    "title": "Galatasaray Stad ve Müze Gezisi",
    "subTitle": "",
    "detail": "Müze ve Stat Turu Kazanan Listesi:\n\nASİL \n1- BERKİN TEMÜR\n2- FERDA ŞAHİN\n3- YUSUF ÇAM\n4- ERTUĞRUL SAFRAN\n5- ÇETİN SÜRUR\n6- MUSTAFA ÖZALP\n7- BERFİN TAN\n8- HASAN AŞAN\n9- KASIM AKTAŞ\n10- SELÇUK ALKAZAN\n11- MERVE AVUNÇ\n12- KAAN BOZKURT\n13- RAMAZAN GÜLENÇ\n14- İREM MIDIK\n15- ÖZGE AY\n16- ALİ GÜNCAN\n17- ALPER YAZICI\n18- KAAN ÖZKAN\n19- AGAH ÖZEROL\n20- ALİ GÜNCAN\n\nYEDEK \n1- TAHIR BAYANA\n2- NURSENA APAYDİN\n3- BERK UĞURLU\n4- BARIŞ YILMAZ\n5- ZEYNEP MENEKŞE\n6- MUSTAFA IŞIK\n7- BEKİR ADA\n8- MERT CAM\n9- BATUHAN KOCA\n10- NAZMİ AKKUZU\n11- AHMET YİĞİT\n12- ALİ ARDA DÖNERGÖZ\n13- GÜLCAN KILIÇASLAN\n14- SAMET YILDIRIM\n15- KEREM ACAR\n16- MEHMET BİÇER\n17- ERKAN DOĞAN\n18- ÖMER EFE KÜPELİ\n19- HAYRİ AKYÜZ\n20- EDANUR UNUTMAZ",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/products/icons/gsmuze.png"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(13);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
