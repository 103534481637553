// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';

import { useLanguage } from '../../contexts/LanguageContext';
import { title } from 'process';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = {
    headerTitle: [
        {
            title: 'Bize Ulaşın',
            languageCode: 'tr'
        },
        {
            title: 'Contact us',
            languageCode: 'en'
        }
    ],
    headerShortDescription: [
        {
            title: 'Ürün ve hizmetlerimiz hakkındaki yorum ve önerilerin bizim için değerli. Aklına takılan soruları yanıtlamak ve sana yardımcı olmak için AHL Pay her zaman yanında.',
            languageCode: 'tr'
        },
        {
            title: 'Your comments and suggestions about our products and services are valuable to us. AHL Pay is always here to answer your questions and help you.',
            languageCode: 'en'
        }
    ],

    customerservice: [
        {
            title: <><h3>Customer service</h3>
                <ul>
                    <li><span>Phone Number:</span> 444 90 66</li>
                    <li>
                        Email: destek@ahlpay.com.tr
                    </li>
                </ul></>,
            languageCode: 'en'
        },
        {
            title: <><h3>Müşteri Hizmetleri</h3>
                <ul>
                    <li><span>Telefon:</span> 444 90 66</li>
                    <li>
                        E-posta: destek@ahlpay.com.tr
                    </li>
                </ul></>,
            languageCode: 'tr'
        }
    ],
    headoffice: [
        {
            title:
                <><h3>Head Office</h3><ul>
                    <li>
                        <span>Addres: </span>
                        İçerenköy Mah. Yeşilvadi Sok. No:3 Kat:8<br />
                        Ataşehir / İstanbul
                    </li>
                    <li><span>Phone Number:</span> 444 90 66</li>
                    <li><span>Email: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },
        {
            title: <><h3>Genel Müdürlük</h3>
                    <h2><strong>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.</strong></h2> 
                <ul>
                    <li>
                        <span>Adres: </span>
                        İçerenköy Mah. Yeşilvadi Sok. No:3 Kat:8<br />
                        Ataşehir / İstanbul
                    </li>
                    <li><span>Telefon:</span> 444 90 66</li>
                    <li><span>E-posta: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'tr'
        }
    ],

    Branch: [
        {
            title:
                <><h3>Branch</h3><ul>
                    <li>
                        <span>Addres: Fulya Mah. Büyükdere Cad. No:40/2 </span>
                        Rumeli İş Hanı <br />
                        Şişli - İstanbul
                    </li>
                    <li><span>Phone Number: 444 90 66</span> </li>
                    <li><span>Email: </span><a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },

        {
            title: <><h3>Şube</h3><ul>
                <li>
                    <span>Adres: Fulya Mah. Büyükdere Cad. No:40/2 </span>
                    Rumeli İş Hanı <br />
                    Şişli - İstanbul
                </li>
                <li><span>Telefon:</span> 444 90 66</li>
                <li><span>E-posta: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                </li>
            </ul></>,
            languageCode: 'tr'
        }
    ],
    BranchCorum: [
        {
            title:
                <><h3>Center</h3><ul>
                    <li>
                        <span>Addres: Eğridere Caddesi No:17 </span>
                        <br />
                        Merkez / Çorum
                    </li>
                    <li><span>Phone Number:</span> 444 0 186</li>
                    <li><span>Email: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },
        {
            title: <><h3>Merkez</h3><ul>
                <li>
                    <span>Adres: Eğridere Caddesi No:17 </span>
                    <br />
                    Merkez / Çorum
                </li>
                <li><span>Telefon: 444 0 186</span> </li>
                <li><span>E-posta: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                </li>
            </ul></>,
            languageCode: 'tr'
        }
    ],
    corpoateInformation: [
        {
            description: <><h3>Kurumsal Bilgiler</h3>
                <ul>
                    <li><span>Ticari Sicil Numarası:</span> 384098-5</li>
                    <li><span>Mersis Numarası:</span> 0010-1392-1610-0001</li>
                    <li>
                        <span>Denetleyici Merci: </span>
                        Türkiye Cumhuriyet Merkez Bankası (TCMB)<br />
                        Hacı Bayram Mah. İstiklal Cad. No:10 PK:06050<br />
                        Ulus - Altındağ / Ankara
                    </li>
                    <li><span>Telefon:</span> 0 (312) 507 50 00</li>
                    <li><span>E-posta: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                    </li>
                    <li>Kep Adresi :ahlatciodeme@hs03.kep.tr</li>
                </ul></>,
            languageCode: 'tr'
        },
        {
            description: <><h3>Corporate Information</h3>
                <ul>
                    <li><span>Commercial Registry Number:</span> 384098-5</li>
                    <li><span>MERSIS Number (Central Registration System Number):</span> 0010-1392-1610-0001</li>
                    <li>
                        <span>Supervisory Authority: </span>
                        Türkiye Cumhuriyet Merkez Bankası (TCMB)<br />
                        Hacı Bayram Mah. İstiklal Cad. No:10 PK:06050<br />
                        Ulus - Altındağ / Ankara
                    </li>
                    <li><span>Phone Number:</span> 0 (312) 507 50 00</li>
                </ul></>,
            languageCode: 'en'
        },

    ]
}
const ContactPage = () => {

    const { language } = useLanguage();

    const childrens = <main page="inside">
        <Helmet><title>Bize Ulaşın</title></Helmet>
        <Section element="pageheader">
            <ContainerDiv>
                <HeaderDiv>
                    <h1><br /></h1>
                    <h1><br /></h1>
                    <h1><br /></h1>
                    <h1>{pageJSON.headerTitle.find(x => x.languageCode == language)?.title}</h1>
                    <h2>
                        {pageJSON.headerShortDescription.find(x => x.languageCode == language)?.title}
                    </h2>
                </HeaderDiv>
                <div>
                    <Image src="/assets/uploads/pageheader/bize_ulasin.png" alt="Bize Ulaşın" />
                </div>
            </ContainerDiv>
        </Section>
        <Section element="contact">
            <div>
                <grid grid="contact" grid-rows="2x">
                    <GridItem grid-item="">
                        {pageJSON.headoffice.find(x => x.languageCode == language)?.title}
                    </GridItem>

                </grid>
                <hr></hr>
                <grid grid="contact" grid-rows="2x">
                    <GridItem grid-item="">
                        {pageJSON.BranchCorum.find(x => x.languageCode == language)?.title}
                    </GridItem>
                    <GridItem grid-item="">
                        {pageJSON.Branch.find(x => x.languageCode == language)?.title}
                    </GridItem>
                </grid>
                <hr />
                <grid grid="contact" grid-rows="2x">
                    <div grid-item="">
                        {pageJSON.corpoateInformation.find(x => x.languageCode == language)?.description}
                    </div>
                    <div grid-item="">
                        {pageJSON.customerservice.find(x => x.languageCode == language)?.title}
                    </div>
                </grid>

            </div>
        </Section>
    </main>;

    return <MainLayout children={childrens} />
};

export default ContactPage;