// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import styles from './HomeProducts.module.css';
import { title } from 'process';
import { useLanguage } from '../../../contexts/LanguageContext';

const pageJSON = [
    {
        id: 6,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'AHL Card',
                childrens: [
                    "Her şey kontrolün altında",
                    "Dilediğin gibi harca, bütçeni aşma",
                    "Harcadıkça Cashback kazan"
                ],
                image: '/assets/uploads/products/icons/cardanasayfa.svg'
            },
            en: {
                title: 'AHL Card',
                childrens: [
                    "Everything is under control",
                    "Spend as you wish without exceeding your budget",
                    "Earn cashback as you spend"
                ],
                image: '/assets/uploads/products/icons/cardanasayfa.svg'
            }
        }
    },
    {
        id: 1,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'IBAN',
                childrens: [
                    "Kolayca IBAN oluştur",
                    "Kopyala, yapıştır, gönder",
                    "KOLAS ile para gönder"
                ],
                image: '/assets/uploads/products/phones/iban_phone.svg'
            },
            en: {
                title: 'IBAN',
                childrens: [
                    "Easily create an IBAN",
                    "Copy, paste, send",
                    "Send money with KOLAS"
                ],
                image: '/assets/uploads/products/phones/iban_phone.svg'
            }
        }
    },
    {
        id: 3,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'Fiziki Altın',
                childrens: [
                    "Adrese teslim",
                    "Avantajlı fiyat",
                    "Yatırımda güvenli liman"
                ],
                image: '/assets/uploads/products/phones/fiziki_phone.svg'
            },
            en: {
                title: 'Physical Gold',
                childrens: [
                    "Home delivery",
                    "Competitive pricing",
                    "Safe haven for investment"
                ],
                image: '/assets/uploads/products/phones/fiziki_phone.svg'
            }
        }
    },
    {
        id: 2,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'FAST',
                childrens: [
                    "Anında hesabında",
                    "7/24 Para transferi",
                    "Kolay ve hızlı"
                ],
                image: '/assets/uploads/products/icons/fasthd.svg'
            },
            en: {
                title: 'FAST',
                childrens: [
                    "Instantly in your account",
                    "24/7 Money transfer",
                    "Easy and fast"
                ],
                image: '/assets/uploads/products/icons/fasthd.svg'
            }
        }
    },
    {
        id: 4,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'Kıymetli Maden',
                childrens: [
                    "Hızlı ve güvenli",
                    "Hemen yatırıma başla",
                    "7/24 güncel kurlarla işlem yap"
                ],
                image: '/assets/uploads/products/icons/kiymetli_madenler.svg'
            },
            en: {
                title: 'Precious Metals',
                childrens: [
                    "Fast and secure",
                    "Start investing immediately",
                    "Trade 24/7 with real-time rates"
                ],
                image: '/assets/uploads/products/icons/kiymetli_madenler.svg'
            }
        }
    },
    {
        id: 5,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'ATOM',
                childrens: [
                    "Anında hesabında",
                    "Tüm kuyum ürünleri",
                    "Altın-TL kuru ile TL’ye çevirme"
                ],
                image: '/assets/uploads/products/icons/atom.svg'
            },
            en: {
                title: 'ATOM',
                childrens: [
                    "Instantly in your account",
                    "All jewelry products",
                    "Convert to TL at Gold-TL rate"
                ],
                image: '/assets/uploads/products/icons/atom.svg'
            }
        }
    },
    {
        id: 8,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'BenimFaturam',
                childrens: [
                    "%1 Nakit iade",
                    "12 Ay taksit",
                    "Güvenli ödeme"
                ],
                image: '/assets/uploads/products/icons/benimfaturam.svg'
            },
            en: {
                title: 'BenimFaturam',
                childrens: [
                    "1% Cash back",
                    "12-month installment",
                    "Secure payment"
                ],
                image: '/assets/uploads/products/icons/benimfaturam.svg'
            }
        }
    },
    {
        id: 7,
        groupCode: 'INDIVIDUAL',
        translations: {
            tr: {
                title: 'Yatırım',
                childrens: [
                    "Akıllı Yatırım, güvenli getiri",
                    "Çeşitli ve esnek yatırım araçları",
                    "Kullanıcı dostu arayüz, işlem kolaylığı"
                ],
                image: '/assets/uploads/products/icons/yatirim.svg'
            },
            en: {
                title: 'Investment',
                childrens: [
                    "Smart investment, secure returns",
                    "Diverse and flexible investment tools",
                    "User-friendly interface, ease of transaction"
                ],
                image: '/assets/uploads/products/icons/yatirim.svg'
            }
        }
    },
    {
        id: 8,
        groupCode: 'BUSINESS',
        translations: {
            tr: {
                title: 'AndroidPOS',
                childrens: [
                    "Kolay ve hızlı işlemler",
                    "E-Fatura ve E-Arşiv fatura",
                    "Avantajlı komisyon oranları"
                ],
                image: '/assets/uploads/products/products/kurumsal_androidpos.png'
            },
            en: {
                title: 'AndroidPOS',
                childrens: [
                    "Easy and fast transactions",
                    "E-Invoice and E-Archive Invoice",
                    "Competitive commission rates"
                ],
                image: '/assets/uploads/products/products/kurumsal_androidpos.png'
            }
        }
    },
    {
        id: 7,
        groupCode: 'BUSINESS',
        translations: {
            tr: {
                title: 'AltınPOS',
                childrens: [
                    "Kuyumculara özel",
                    "Avantajlı TL-Altın kuru",
                    "Fiziki teslimat"
                ],
                image: '/assets/uploads/products/products/kurumsal_altinpos.png'
            },
            en: {
                title: 'AltınPOS',
                childrens: [
                    "Exclusive for jewelers",
                    "Advantageous TL-Gold rate",
                    "Physical delivery"
                ],
                image: '/assets/uploads/products/products/kurumsal_altinpos.png'
            }
        }
    },
    {
        id: 11,
        groupCode: 'BUSINESS',
        translations: {
            tr: {
                title: 'Yazar Kasa POS',
                childrens: [
                    "Mevzuat uygunluğu ve yasal uyumluluk",
                    "Güvenli ödeme",
                    "Hızlı kurulum, kolay kullanım"
                ],
                image: '/assets/uploads/products/products/kurumsal_yazarkasapos.png'
            },
            en: {
                title: 'Cash Register POS',
                childrens: [
                    "Compliance with regulations and legal requirements",
                    "Secure payment",
                    "Quick setup, easy use"
                ],
                image: '/assets/uploads/products/products/kurumsal_yazarkasapos.png'
            }
        }
    },
    {
        id: 9,
        groupCode: 'BUSINESS',
        translations: {
            tr: {
                title: 'CepPOS',
                childrens: [
                    "Taşınabilir ve temassız",
                    "Lokasyon bağımsız",
                    "Anında ödeme alma"
                ],
                image: '/assets/uploads/products/products/kurumsal_ceppos.png'
            },
            en: {
                title: 'CepPOS',
                childrens: [
                    "Portable and contactless",
                    "Location independent",
                    "Instant payment acceptance"
                ],
                image: '/assets/uploads/products/products/kurumsal_ceppos.png'
            }
        }
    },
    {
        id: 10,
        groupCode: 'BUSINESS',
        translations: {
            tr: {
                title: 'SanalPOS',
                childrens: [
                    "Hızlı ve pratik",
                    "Tüm işlemler tek portalda",
                    "Avantajlı komisyon oranları"
                ],
                image: '/assets/uploads/products/products/sanalpos_odeme_pc.png'
            },
            en: {
                title: 'SanalPOS',    
                childrens: [
                    "Fast and practical",
                    "All transactions in one portal",
                    "Competitive commission rates"
                ],
                image: '/assets/uploads/products/products/sanalpos_odeme_pc.png'
            }
        }
    }
];


export const HomeProducts = () => {
    const { language } = useLanguage();
    const [activeProductType, setActiveProductType] = useState("INDIVIDUAL");
    const [activeItem, setActiveItem] = useState(
        pageJSON.filter(x => x.groupCode === activeProductType)[0]
    );

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    return (
        <>
            <section element="homeproducts">
                <div>
                    <h1 className={styles.centerTitle}>
                        {language === 'tr'
                            ? 'Farklı Finansal Çözümler Tek Uygulamada'
                            : 'Various Financial Solutions in One App'}
                    </h1>
                </div>
                <div>
                    <ul tabs="">
                        <li
                            tab-id="individual"
                            active={activeProductType === "INDIVIDUAL" ? 'true' : undefined}
                            onClick={() => {
                                setActiveProductType("INDIVIDUAL");
                                setActiveItem(
                                    pageJSON.filter(x => x.groupCode === "INDIVIDUAL")[0]
                                );
                            }}
                        >
                            {language === 'tr' ? 'Bireysel' : 'Individual'}
                        </li>
                        <li
                            tab-id="corporate"
                            active={activeProductType === "BUSINESS" ? 'true' : undefined}
                            onClick={() => {
                                setActiveProductType("BUSINESS");
                                setActiveItem(
                                    pageJSON.filter(x => x.groupCode === "BUSINESS")[0]
                                );
                            }}
                        >
                            {language === 'tr' ? 'Kurumsal' : 'Corporate'}
                        </li>
                    </ul>

                    <div className={styles.container}>
                        <div className={styles.leftSection}>
                            <ul>
                                {pageJSON
                                    .filter(x => x.groupCode === activeProductType)
                                    .map((item) => (
                                        <li
                                            className={activeItem.id === item.id ? styles.active : ''}
                                            onClick={() => handleItemClick(item)}
                                            onMouseEnter={() => handleItemClick(item)}
                                        >
                                            {item.translations[language].title}
                                        </li>
                                    ))}
                            </ul>
                        </div>

                        <div className={styles.middleSection}>
                            <div className={styles.phoneMockup}>
                                <img
                                    src={activeItem.translations[language].image}
                                    alt={activeItem.translations[language].title}
                                />
                            </div>
                        </div>

                        <div className={styles.rightSection}>
                            <ul>
                                {activeItem.translations[language].childrens.map((child, index) => (
                                    <li key={index}>{child}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};