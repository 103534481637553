// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Para Yükle Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay ile Para Yükle',
            headerTitle: 'Para Yükle',
            title: "AHL Pay ile Para Yükle",
            shortDescription:
                'Para Yükleme işlemi ile finansal özgürlüğünü kontrol altında tut. Online bankacılık işlemlerini kolaylaştır ve paranı her zaman, her yerde kullanıma hazır hale getir.',
            description:
                "Transferler ekranında yer alan Para Yükle seçeneğine tıklayarak, karşına gelen bilgiler ile, şahsi banka hesabından AHL Pay hesabına para yükleme işlemini güvenle yapabilirsin. Para yükleme işlemi anında gerçekleşir, bu sayede paran hesabında hemen kullanıma hazır hale gelir. Bakiyeni mobil ödemelerinde, online alışverişlerinde veya fatura ödemelerinde rahatlıkla kullanabilirsin",
            imagePath: 'assets/uploads/products/phones/parayukle.svg',
            title2: <>Nasıl<span> Para yüklerim?</span></>,
            childrens: [
                {
                    title: 'Kolayca Para Yükle',
                    desc: "Banka hesaplarından veya AHL Card ile tüm ATM'lerden para yükle"
                },
                {
                    title: 'Hızlı ve Güvenli',
                    desc: 'Güvenle para yatır, anında hesabına geçsin'
                },
                {
                    title: 'Keyifle Harca',
                    desc: 'Bakiyeni yurt içindeki alışverişlerinde ve e-ticaret sitelerinde kullan'
                }
            ]
        },
        en: {
            pageTitle: 'Load Money with AHL Pay',
            headerTitle: 'Load Money',
            title: "Load Money with AHL Pay",
            shortDescription:
                'Keep your financial freedom under control with the Load Money feature. Simplify online banking transactions and make your money ready for use anytime, anywhere.',
            description:
                "Click the Load Money option on the Transfers screen and securely load money from your personal bank account to your AHL Pay account using the provided details. The load money process happens instantly, making your funds immediately available for use. You can conveniently use your balance for mobile payments, online shopping, or bill payments.",
            imagePath: 'assets/uploads/products/phones/parayukle.svg',
            title2: <>How to<span> Load Money?</span></>,
            childrens: [
                {
                    title: 'Load Money Easily',
                    desc: "Load money from bank accounts or via all ATMs using AHL Card"
                },
                {
                    title: 'Fast and Secure',
                    desc: 'Deposit money securely and have it instantly credited to your account'
                },
                {
                    title: 'Spend with Joy',
                    desc: 'Use your balance for domestic shopping and e-commerce platforms'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
