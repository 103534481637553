// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';
 
const Section = styled.section``;
const campaigns = [
  {
    "id": 1,
    "title": "Herkese Galatasaray Atkısı",
    "subTitle": "",
    "detail": "Galatasaray – AHL Pay Atkı Kazanma Koşulları: Galatasaray - AHL Pay atkı ödülüne hak kazanabilmek için katılımcının AHL Pay'de onaylı bir hesabının olması, hesabına en az 1,00 ₺ yüklemesi ve en az 1 işlem gerçekleştirmesi gerekmektedir. Başlangıç hesap sahibi katılımcıların ödülden yararlanabilmeleri için onaylı hesap türüne geçmeleri zorunludur. Katılım, AHL Pay uygulaması üzerinden 'GALATASARAY – AHL Pay Atkı' kampanyasına katıl butonu ile alınan kodun, maç günlerinde RAMS Park'ta bulunan AHL Pay Fan Zone karavanında beyan edilmesi ile gerçekleşir ve atkı temin edilir. Kullanıcı bilgilerinin doğruluğu katılımcının sorumluluğundadır; yanlış bilgi verilmesi durumunda ödül teslim edilmez. Önceki kampanyalarda aynı ödülü kazananlar yeniden katılamaz. AHL Pay, kampanya süresini veya koşullarını önceden haber vermeksizin değiştirme veya iptal etme hakkına sahiptir. Katılımcılar, teknik aksaklıklardan kaynaklanan hak kayıplarından sorumlu tutulamaz ve tüm katılımcılar bu koşulları peşinen kabul eder.",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/products/icons/gsatkı.jpeg"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(1);
  const location = useLocation();
 
  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
 
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };
 
  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};
 
const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}
 
export default CampaignsPage;