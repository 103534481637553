// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = {
    title: [
        {
            title: 'Sertifikalar',
            languageCode: 'tr'
        },
        {
            title: 'Certificates',
            languageCode: 'en'
        }
    ]
}

const Certificates = () => {

    const { language } = useLanguage();

    const childrens = <main page="inside">
        <Helmet><title>Sertifikalar</title></Helmet>

        <section element="certificates">

            <h1>{pageJSON.title.find(x => x.languageCode == language)?.title}</h1>

            <div>
                <grid grid="certificates">
                    <div grid-item="">
                        <a href='../assets/certificates/iso_223012019.pdf' target='_blank'>
                            <picture><img src="/assets/uploads/certificates/iom_logo1.png" alt="ISO_223012019" /></picture>
                            <div>
                                <h3>ISO_223012019</h3>
                            </div>
                        </a>
                    </div>
                    <div grid-item="">
                        <a href='../assets/certificates/iso_270012022.pdf' target='_blank'>
                            <picture><img src="/assets/uploads/certificates/iom_logo3.png" alt="ISO_270012022" /></picture>
                            <div>
                                <h3>ISO_270012022</h3>
                            </div>
                        </a>
                    </div>
                    <div grid-item="">
                        <a href='../assets/certificates/iso_277012019.pdf' target='_blank'>
                            <picture><img src="/assets/uploads/certificates/iom_logo2.png" alt="ISO_77012019" /></picture>
                            <div>
                                <h3>ISO_77012019</h3>
                            </div>
                        </a>
                    </div>
                </grid>
            </div>
        </section>
    </main>

    return <MainLayout children={childrens} />
};


export default Certificates;