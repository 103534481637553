// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Kurumsal Hesap Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay Kurumsal Hesap',
            headerTitle: 'Kurumsal Hesap',
            title: "AHL Pay Kurumsal Hesap",
            shortDescription:
                'AHL Pay Kurumsal Hesap ile, işletme harcamalarını daha etkin bir şekilde yönetebilir, ödemelerinizi hızlı bir şekilde gerçekleştirebilir ve nakit akışını optimize edebilirsin. Ayrıca, işletmene özel raporlama ve analiz araçları sayesinde mali durumunu detaylı bir şekilde izleme fırsatı bulursun.',
            description:
                'İşletmenin finansal süreçlerini etkin bir şekilde yönet. Hızlı ve kolay işlemlerle, ödemelerini zamanında gerçekleştir. Gelişmiş güvenlik önlemleri sayesinde finansal bilgilerini koru.',
            imagePath: 'assets/uploads/products/phones/kurumsalhesap.png',
            title2: <><span>Kurumsal Hesap </span>Avantajları</>,
            childrens: [
                {
                    title: 'Güvenli İşlemler',
                    desc: 'Yüksek güvenlik standartları ile finansal bilgilerinin korunmasını sağla, dolandırıcılık riskini azalt.'
                },
                {
                    title: 'Finansal Yönetim Kolaylığı',
                    desc: 'Tüm ticari işlemlerini tek bir hesapta yöneterek, mali süreçlerini daha basit ve düzenli hale getir.'
                },
                {
                    title: 'Raporlama',
                    desc: 'Detaylı raporlama ile nakit akışını ve harcamalarını etkili bir şekilde takip et.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay Corporate Account',
            headerTitle: 'Corporate Account',
            title: "AHL Pay Corporate Account",
            shortDescription:
                'With the AHL Pay Corporate Account, you can manage your business expenses more efficiently, make payments quickly, and optimize cash flow. Additionally, take advantage of reporting and analysis tools tailored for your business to monitor your financial situation in detail.',
            description:
                'Effectively manage the financial processes of your business. Make payments on time with fast and easy transactions. Protect your financial information with advanced security measures.',
            imagePath: 'assets/uploads/products/phones/kurumsalhesap.png',
            title2: <><span>Corporate Account </span>Advantages</>,
            childrens: [
                {
                    title: 'Secure Transactions',
                    desc: 'Ensure the protection of your financial information with high security standards, reducing the risk of fraud.'
                },
                {
                    title: 'Ease of Financial Management',
                    desc: 'Manage all your commercial transactions in one account, simplifying and organizing your financial processes.'
                },
                {
                    title: 'Reporting',
                    desc: 'Track your cash flow and expenses effectively with detailed reporting.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
