// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [
  {
    "id": 10,
    "title": "BRILLANTSTORE'dan %30 İndirim + Ücretsiz Kargo",
    "subTitle": "",
    "detail": "Tüm ürünlerde geçerli %30 indirim ve ücretsiz kargo avantajı! Brillantstore.com.tr’de yer alan tüm ürünlerde geçerlidir. (herhangi bir istisna ya da alt/üst sepet sınırı bulunmamaktadır) Kampanyadan 30.11.2024 tarihine kadar kod alabilirsiniz, kodların son kullanım tarihi 31.12.2024'tür. %30 indirim ve ücretsiz kargo avantajımızın sınırı bulunmamaktadır yani sınırsızdır.\n\nKodu kullanabilmek için, beğendiğiniz ürünü sepetinize ekledikten sonra, sepet ekranında yer alan kupon kodu alanına kupon kodunuzu yazıp 'kullan' demeniz yeterlidir. AHL Pay ve Brillantstore kampanya kurgusunu değiştirme veya kampanyayı sonlandırma hakkını saklı tutar.",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/campaigns/detail/brillant.jpeg"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(10);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  {item.subTitle && <h3>{item.subTitle}</h3>}
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
