// @ts-nocheck
import { RouterProvider } from "react-router-dom";
import LiveChat from "./app/components/LiveChat";
import { router } from "./app/router/Router";

function App() {
  return (
    <>
      <RouterProvider router={router()} />
      <LiveChat/>
    </>
  );
}                                                                         
//
export default App;