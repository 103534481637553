// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Kurumsal Kart Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage();
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay Kurumsal Kart',
            headerTitle: 'Kurumsal Kart',
            title: "AHL Pay Kurumsal Kart",
            shortDescription:
                'Şirket içindeki finansal işlemleri daha verimli ve kontrol edilebilir hale getirin. AHL Pay Kurumsal Kart, şirket içi harcamalarında kullanabileceğin güvenli bir ödeme aracıdır.',
            description:
                'İşletmenin finansal yönetimini kolaylaştır. Harcama limitleri ve detaylı raporlama imkanları sayesinde bütçe kontrolünü etkili bir şekilde sağla, mali süreçlerini planlı ve düzenli yürüt.',
            imagePath: 'assets/uploads/products/phones/Kurumsalkart.png',
            title2: <><span>AHL Pay </span>Kurumsal Kart</>,
            childrens: [
                {
                    title: 'Kolaylık',
                    desc: 'Harcamalarını hızlı ve pratik bir şekilde gerçekleştir.'
                },
                {
                    title: 'Kontrol',
                    desc: 'Harcama limitleri ve raporlama özellikleri sayesinde bütçeni etkin bir şekilde yönet.'
                },
                {
                    title: 'Şeffaflık',
                    desc: 'Kolay kurulum ve yönetim ile işini hızla büyüt.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay Corporate Card',
            headerTitle: 'Corporate Card',
            title: "AHL Pay Corporate Card",
            shortDescription:
                'Make internal company financial transactions more efficient and controllable. The AHL Pay Corporate Card is a secure payment tool for your internal company expenses.',
            description:
                'Simplify the financial management of your business. Ensure effective budget control with spending limits and detailed reporting capabilities, running your financial processes in a planned and organized way.',
            imagePath: 'assets/uploads/products/phones/Kurumsalkart.png',
            title2: <><span>AHL Pay </span>Corporate Card</>,
            childrens: [
                {
                    title: 'Convenience',
                    desc: 'Carry out your expenses quickly and practically.'
                },
                {
                    title: 'Control',
                    desc: 'Manage your budget effectively with spending limits and reporting features.'
                },
                {
                    title: 'Transparency',
                    desc: 'Grow your business quickly with easy setup and management.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
