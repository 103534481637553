import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const NavBar = () => {
  const { language } = useLanguage();

  const navItems = {
    tr: [
      { path: '/bireysel-urunler/kiymetli-maden-alim-satim', label: 'Kıymetli Maden' },
      { path: '/bireysel-urunler/ahlcard', label: 'AHL Card' },
      { path: '/bireysel-urunler/fizikialtinvegumus', label: 'Fiziki Altın/Gümüş' },
      { path: '/bireysel-urunler/atom', label: 'ATOM' },
      { path: '/bireysel-urunler/para-gonder', label: 'Para Gönder' },
      { path: '/bireysel-urunler/para-yukle', label: 'Para Yükle' },
      { path: '/bireysel-urunler/para-iste', label: 'Para İste' },
      { path: '/bireysel-urunler/fatura-ode', label: 'Fatura Öde' },
      { path: '/bireysel-urunler/yatirim-urunleri', label: 'Yatırım' },
      { path: '/bireysel-urunler/oyun-ve-dijital-kod', label: 'Oyun & Dijital Kod' },
      { path: '/bireysel-urunler/bagis', label: 'Bağış' },
      { path: '/bireysel-urunler/guvencebedeliiadesi', label: 'Güvence Bedeli' },
    ],
    en: [
      { path: '/bireysel-urunler/kiymetli-maden-alim-satim', label: 'Precious Metals' },
      { path: '/bireysel-urunler/ahlcard', label: 'AHL Card' },
      { path: '/bireysel-urunler/fizikialtinvegumus', label: 'Physical Gold/Silver' },
      { path: '/bireysel-urunler/atom', label: 'ATOM' },
      { path: '/bireysel-urunler/para-gonder', label: 'Send Money' },
      { path: '/bireysel-urunler/para-yukle', label: 'Load Money' },
      { path: '/bireysel-urunler/para-iste', label: 'Request Money' },
      { path: '/bireysel-urunler/fatura-ode', label: 'Pay Bill' },
      { path: '/bireysel-urunler/yatirim-urunleri', label: 'Investment' },
      { path: '/bireysel-urunler/oyun-ve-dijital-kod', label: 'Game & Digital Code' },
      { path: '/bireysel-urunler/bagis', label: 'Donation' },
      { path: '/bireysel-urunler/guvencebedeliiadesi', label: 'Security Deposit' },
    ],
  };

  return (
    <nav style={styles.navbar}>
      <ul style={styles.navLinks}>
        {navItems[language]?.map((item, index) => (
          <li key={index} style={styles.navItem}>
            <Link to={item.path} style={styles.navLink}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const styles = {
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    WebkitOverflowScrolling: 'touch',
  },
  navLinks: {
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    overflowX: 'auto', overflowY: 'hidden',
  },
  navItem: {
    marginLeft: '25px',
    flexShrink: 0,
  },
  navLink: {
    textDecoration: 'none',
    color: '#6c757d',
    fontSize: '16px',
    fontWeight: '350',
    lineHeight: '1.5',
    padding: '5px 0',
  },
};

export default NavBar;
