// @ts-nocheck
import { createRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouterList, GetRouteDetail } from '../router/Router';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { useLanguage } from '../contexts/LanguageContext';
import PageKeys from '../constants/Pagekeys';
const Header = styled.header``;
const InnerDiv = styled.div``;
const LogoDiv = styled.div``;
const NavDiv = styled.div``;
const SubmenuDiv = styled.div``;
const QuickDiv = styled.div``;
const QuickAppsDiv = styled.div``;
const LoginCorpDiv = styled.div``;

const pageJSON = {
    products: [
        {
            title: 'Ürünler',
            languageCode: 'tr'
        },
        {
            title: 'Products',
            languageCode: 'en'
        }
    ],
    productsSubTitle: [
        {
            title: 'Hem bireysel hem kurumsal müşterilerimiz için sunduğumuz çözümler',
            languageCode: 'tr'
        },
        {
            title: 'Solutions we offer for both our individual and corporate customers.',
            languageCode: 'en'
        }
    ],
    product1: [
        {
            title: 'Bireysel Ürünler',
            languageCode: 'tr'
        },
        {
            title: 'Retail Products',
            languageCode: 'en'
        }
    ],
    product2: [
        {
            title: 'Kurumsal Ürünler',
            languageCode: 'tr'
        },
        {
            title: 'Corporote Products',
            languageCode: 'en'
        }
    ],
    home: [
        {
            title: 'Ana Sayfa',
            languageCode: 'tr'
        },
        {
            title: 'Home',
            languageCode: 'en'
        }
    ],
    news: [
        {
            title: 'Güncel',
            languageCode: 'tr'
        },
        {
            title: 'News',
            languageCode: 'en'
        }
    ],
    contact: [
        {
            title: 'İletişim',
            languageCode: 'tr'
        },
        {
            title: 'Contact',
            languageCode: 'en'
        }
    ],
    online: [
        {
            title: 'Online İşlemler',
            languageCode: 'tr'
        },
        {
            title: 'Online Transactions',
            languageCode: 'en'
        }
    ],
    invoice: [
        {
            title: 'BenimFaturam',
            languageCode: 'tr'
        },
        {
            title: 'Pay Invoice',
            languageCode: 'en'
        }
    ],
    corporateLogin: [
        {
            title: 'Kurumsal Giriş',
            languageCode: 'tr'
        },
        {
            title: 'Corporate Login',
            languageCode: 'en'
        }
    ],
    FeesLmts: [
        {
            title: 'Ücret ve Limitler',
            languageCode: 'tr'
        },
        {
            title: 'Fees and Limits',
            languageCode: 'en'
        }
    ],
    campaigns: [
        {
            title: 'Kampanyalar',
            languageCode: 'tr'
        },
        {
            title: 'Campaigns',
            languageCode: 'en'
        }
    ],
    Career: [
        {
            title: 'Kariyer',
            languageCode: 'tr'
        },
        {
            title: 'Career',
            languageCode: 'en'
        }
    ],
    C: [
        {
            title: 'Bize Ulaşın',
            languageCode: 'tr'
        },
        {
            title: 'Contact Us',
            languageCode: 'en'
        }
    ],
};

const AppHeader = () => {
    const { language, setLanguage } = useLanguage();
    const [isSticky, setIsSticky] = useState(false);

    const handleLanguageChange = (newLanguage: string) => {
        setLanguage(newLanguage);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <Header header={true ? 'sticky' : 'header'}>
        <InnerDiv hrole={`inner`}>
            <div hinner={`menu-mobil`}>
                <span menu-opener="">
                    <img src="/assets/images/icon-menu.png" alt="Open Menu" />
                </span>
                <span menu-dismiss="">
                    <img src="/assets/images/icon-close.png" alt="Close Menu" />
                </span>
                <div menu-content="">
                    <nav>
                        <a href="/" title="Anasayfa">{pageJSON.home.find(x => x.languageCode == language)?.title}</a>
                        {_buildMobileMenuItem(PageKeys.PRODUCTS_INDIVIDUAL, language)}
                        {_buildMobileMenuItem(PageKeys.PRODUCTS_CORPORATE, language)}
                        {_buildMobileMenuItem(PageKeys.CAMPAIGNS, language)}
                        {_buildMobileMenuItem(PageKeys.FEES_AND_LIMITS, language)}
                        {_buildMobileMenuItem(PageKeys.CONTACT_US, language)}
                        {_buildMobileMenuItem(PageKeys.FAQ, language)}
                        {_buildMobileMenuItem(PageKeys.CAREER, language)}
                        <ul quick="apps">
                            <li><a href="https://apps.apple.com/tr/app/ahl-pay/id6443716011" target='_blank' title="AHL Pay: IOS Uygulaması"><img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" /></a></li>
                            <li><a href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr" target='_blank' title="AHL Pay: Android Uygulaması"><img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" /></a></li>
                        </ul>
                        <ul quick="langs">
                            <li><a href="javascript:void(0)" onClick={() => handleLanguageChange('tr')} title="Türkçe">Türkçe</a></li>
                            <li><a href="javascript:void(0)" onClick={() => handleLanguageChange('en')} title="English">English</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div hinner="menu-tablet">
                <span menu-opener=""><img src="/assets/images/icon-menu.png" /></span>
                <span menu-dismiss=""><img src="/assets/images/icon-close.png" /></span>
                <div menu-content="">
                    <nav>
                        <div submenu="">
                            <div aria-label="header">{pageJSON.products.find(x => x.languageCode == language)?.title}</div>
                            <div aria-label="desc">{pageJSON.productsSubTitle.find(x => x.languageCode == language)?.title}</div>
                            <ul>
                                {_buildSubMenuItem(PageKeys.PRODUCTS_CORPORATE, language, "/assets/uploads/menus/kurumsal_cozumler.png")}
                                {_buildSubMenuItem(PageKeys.PRODUCTS_INDIVIDUAL, language, "/assets/uploads/menus/bireysel_cozumler.png")}
                            </ul>
                        </div>
                        <div submenu="">
                            <div aria-label="header">{pageJSON.news.find(x => x.languageCode == language)?.title}</div>
                            <ul>
                                {_buildSubMenuItem(PageKeys.CAMPAIGNS, language, "/assets/uploads/menus/kampanyalarimiz.png")}
                                {_buildSubMenuItem(PageKeys.FEES_AND_LIMITS, language, "/assets/uploads/menus/ucret_ve_limitler.png")}
                            </ul>
                        </div>
                        <div submenu="">
                            <div aria-label="header">{pageJSON.contact.find(x => x.languageCode == language)?.title}</div>
                            <ul>
                                {_buildSubMenuItem(PageKeys.FAQ, language, "/assets/uploads/menus/sss.png")}
                                {_buildSubMenuItem(PageKeys.CONTACT_US, language, "/assets/uploads/menus/bize_ulasin.png")}
                                {_buildSubMenuItem(PageKeys.CAREER, language, "/assets/uploads/menus/kariyer.png")}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <LogoDiv hinner="logo">
                <a href="/" title="AHL Pay">
                    <img src="../assets/logo.png" alt="AHL Pay" />
                </a>
            </LogoDiv>
            <NavDiv hinner="nav">
                <nav>
                    <ul>
                        <li active>
                        </li>
                        {/* <li>
                            <a href="#" title="Ürünler">{pageJSON.products.find(x => x.languageCode == language)?.title}</a>
                            <SubmenuDiv submenu="duo">
                                <div aria-label="header">{pageJSON.products.find(x => x.languageCode == language)?.title}</div>
                                <div aria-label="desc">{pageJSON.productsSubTitle.find(x => x.languageCode == language)?.title}</div>
                                <ul>
                                    {_buildSubMenuItem(PageKeys.PRODUCTS_INDIVIDUAL, language, "assets/uploads/menus/bireysel_cozumler.png")}
                                    {_buildSubMenuItem(PageKeys.PRODUCTS_CORPORATE, language, "assets/uploads/menus/kurumsal_cozumler.png")}
                                </ul>
                            </SubmenuDiv>
                        </li> */}
                        <li>
                            {_buildMobileMenuItem(PageKeys.PRODUCTS_INDIVIDUAL, language)}
                        </li>
                        <li>
                            {_buildMobileMenuItem(PageKeys.PRODUCTS_CORPORATE, language)}
                        </li>
                        <li>
                            <a href="/kampanya-detay" title="Güncel">{pageJSON.campaigns.find(x => x.languageCode == language)?.title}</a>
                        </li>
                        <li>
                            <a href="/ucret-ve-limitler" title="Güncel">{pageJSON.FeesLmts.find(x => x.languageCode == language)?.title}</a>
                        </li>
                        <li>
                            <a href="#" title="İletişim">{pageJSON.contact.find(x => x.languageCode == language)?.title}</a>
                            <SubmenuDiv submenu="trio">
                                <div aria-label="header">{pageJSON.contact.find(x => x.languageCode == language)?.title}</div>
                                <ul>
                                    {_buildSubMenuItem(PageKeys.FAQ, language, "/assets/uploads/menus/sss.png")}
                                    {_buildSubMenuItem(PageKeys.CONTACT_US, language, "/assets/uploads/menus/bize_ulasin.png")}
                                    {_buildSubMenuItem(PageKeys.CAREER, language, "/assets/uploads/menus/kariyer.png")}
                                </ul>
                            </SubmenuDiv>
                        </li>
                    </ul>
                </nav>
            </NavDiv>
            <QuickDiv hinner="quick">
                <div quick="online">
                    <span>{pageJSON.online.find(x => x.languageCode == language)?.title}</span>
                    <div>
                        <ul>
                            <li><a href="https://benimfaturam.com.tr/" title="Fatura Öde">{pageJSON.invoice.find(x => x.languageCode == language)?.title}</a></li>
                            <li><a href="https://www.ahlpay.com.tr/Kurumsal/authentication/login" title="Kurumsal Giriş">{pageJSON.corporateLogin.find(x => x.languageCode == language)?.title}</a></li>
                        </ul>
                    </div>
                </div>
                <QuickAppsDiv quick="apps">
                    <ul>
                        <li>
                            <a href="https://apps.apple.com/tr/app/ahl-pay/id6443716011" target='_blank' title="AHL Pay: IOS Uygulaması">
                                <img src="../assets/app-ios-sm.png" alt="AHL Pay: IOS Uygulaması" />
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr" target='_blank' title="AHL Pay: Android Uygulaması">
                                <img src="../assets/app-android-sm.png" alt="AHL Pay: Android Uygulaması" />
                            </a>
                        </li>
                    </ul>
                </QuickAppsDiv>
                <LanguageSwitcher />
            </QuickDiv>
        </InnerDiv>
    </Header>
};

const _buildMobileMenuItem = (unique, language) => {
    const routeDetail = GetRouteDetail(unique, language);

    return <a href={routeDetail?.path} title="Anasayfa">{routeDetail.title}</a>;
}
const _buildSubMenuItem = (unique, language, imgPath) => {

    const routeDetail = GetRouteDetail(unique, language)

    return <li>
        <a href={routeDetail?.path} title={routeDetail.title}>
            <picture>
                <img src={imgPath} alt={routeDetail.title} />
            </picture>
            {routeDetail.title}
        </a>
    </li>
}

export default AppHeader;