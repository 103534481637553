// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [

  {
    id: 18,
    title: "* 14.09.2024 GS - RİZ Maçı Talihlileri",
    subTitle: "",
    detail:"Galatasaray – Rize Trendyol Süper Lig Maç Bileti Talihlileri:\n\nASİL \n1- İHSAN YILDIZ\n2- YÜKSEL AĞAÇ\n3- MİRAÇ KABADAYI\n4- ONUR DUHAN AYGÜN\n5- FEVZİ ANIL AKBAYIR\n6- MERT ÇALIŞIR\n7- MUHAMMED ENES GÜVEN\n8- MUHAMMET EKREM ŞAHİNOĞLU\n9- OSMAN AYDOĞAN\n10- SELÇUK İNAL\n\nYEDEK \n1- ETHEM TEKŞEN\n2- SEDAT KARAYTU\n3- İLAYDA YAŞAR\n4- ÖMER ATILGAN\n5- EMİNE DURDERE\n6- AHMET GÜNEŞ\n7- SEMİH ÇOLAK",
    languageCode: "tr",
    imagePath: "/assets/uploads/products/icons/gsriz.png",
  },
];

  const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(18);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId));
    }
  }, [location]);
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split("\n").map((line, index) => <p key={index}>{line}</p>);
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <KampanyaNavBar />
          </aside>
          <content>
            {campaigns.map((item) => {
              return item.id === activeCampaign ? (
                <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>{renderCampaignDetail(item?.detail || "")}</div>
                  </article>
                </>
              ) : (
                <></>
              );
            })}
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

export default CampaignsPage;
