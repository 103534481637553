// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import KampanyaNavBar from '../../../components/KampanyaNavBar';

const Section = styled.section``;
const campaigns = [
  {
    "id": 12,
    "title": "Soruyu Bil, İmzalı forma ve şort kazan",
    "subTitle": "",
    "detail": "İmzalı Galatasaray Forma ve Şort Talihlileri:\n\nASİL \n1- MECİT ENGİN KANTAR\n2- AHMET BERK AYLAK\n3- DUHAN AK\n4- EMİRHAN VARİÇLİ\n5- MUSTAFA SARAY\n6- ABDURRAHMAN EZRİK\n7- BÜNYAMİN COŞKUN\n8- HARUN BULUT\n9- EMRE KARAGÖZ\n10- BARAN ARDA DORUKÖZ\n\nYEDEK \n1- ŞÜKRİYE SEVİNÇ\n2- AKİF KONCA\n3- VOLKAN ÇELİKOĞLU\n4- EDA CELİK\n5- ESMA ÖZSOBACI\n6- ARDA OCAK\n7- ALİ CAN YALÇIN\n8- İLKER HACIOĞLU\n9- EMRE CAKIR\n10- ABDULLAH ÖZDAL\n11- METİN TİRYAKİ\n12- GIYASETTİN YASİN KAVAK\n13- İREM MIDIK\n14- SEZER AYDIN\n15- TALHA ÇOPUROĞLU",
    "languageCode": "tr",
    "imagePath": "/assets/uploads/products/icons/gsforma.jpeg"
  }
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(12);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId))
    }
  }, [location])
  useEffect(() => {
    const activeCampaignTitle = campaigns.find((item) => item.id === activeCampaign)?.title;
    document.title = activeCampaignTitle
      ? `${activeCampaignTitle} - Galatasaray Kampanyası`
      : "Galatasaray Kampanyası";
  }, [activeCampaign]);
  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Section element="campaigns">
        <div>
          <aside>
            <ul>
            <KampanyaNavBar />
              {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
            </ul>
          </aside>
          <content>
            {
              campaigns.map((item) => {
                return item.id === activeCampaign ? <>
                  <h1>{item.title}</h1>
                  <picture>
                    <img src={item.imagePath} alt={item.title} />
                  </picture>
                  <article>
                    <div>
                      {renderCampaignDetail(item?.detail || '')}
                    </div>
                  </article>
                </> : <></>
              })
            }
          </content>
        </div>
      </Section>
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      {/* <a href="#" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a> */}
    </li>
  ));
}

export default CampaignsPage;
