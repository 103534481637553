// @ts-nocheck
import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const LanguageSwitcher: React.FC = () => {
    const { language, setLanguage } = useLanguage();

    const handleLanguageChange = (newLanguage: string) => {
        setLanguage(newLanguage);
    };
    return (
        <div quick="langs">
            <span>{language == 'en' ? "English" : "Türkçe"}</span>
            <div>
                <label></label>
                <ul>
                    <li onClick={() => handleLanguageChange('tr')} className={language === 'tr' ? 'active' : ''}>
                        <a href="javascript:void(0)" title="Türkçe">Türkçe</a>
                    </li>
                    <li onClick={() => handleLanguageChange('en')} className={language === 'en' ? 'active' : ''}>
                        <a href="javascript:void(0)" title="English">English</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LanguageSwitcher;