// @ts-nocheck
import { title } from "process";
import { createBrowserRouter } from "react-router-dom";
import ContactPage from "../pages/contact/contact";
import Home from "../pages/home/home";
import FAQPage from "../pages/faq/FAQ";
import Career from "../pages/career/career";
import CareerDetail from "../pages/career/career-detail";
import PageKeys from "../constants/Pagekeys";
import AboutLogos from "../pages/about-logos/about-logos";
import AboutMedia from "../pages/about-media/about-media";
import Agreements from "../pages/agreements/agreements";
import Certificates from "../pages/certificates/certificates";
import Forms from "../pages/forms/forms";
import LegalInfos from "../pages/legalinfos/legalinfos";
import AboutUsPage from "../pages/about-us/AboutUs";
import FeesAndLimitsPage from "../pages/feesAndLimits/FeesAndLimitsPage";
import BlogPage from "../pages/blogs/Blogs";
import BlogDetailsPage from "../pages/blogs/BlogDetail";
import ProductCorporatePage from "../pages/products/Corporate";
import ProductIndividualPage from "../pages/products/basvuru";
import CampaignsPage from "../pages/campaigns/campaigns";
import ProductIndividualV2Page from "../pages/products/IndividualV2";
import ProductCorporateV2Page from "../pages/products/Corporate2";
import ProductApplicationModal from "../pages/products/modals/ProductApplication";
import ApplyPage from "../pages/apply/apply";
import LiveChat from "./app/components/LiveChat";
import NotFound from "../pages/notfound/NotFound";
import AhlCard from '../pages/test/bireysel/ahlcard';
import Atom from '../pages/test/bireysel/atom';
import BireyselYatirim from '../pages/test/bireysel/bireyselyatirim';
import FaturaOde from '../pages/test/bireysel/faturaode';
import FizikiAltinVeGumus from '../pages/test/bireysel/fizikialtinvegumus';
import GuvenceBedeli from '../pages/test/bireysel/guvencebedeli';
import KiymetliMaden from '../pages/test/bireysel/kiymetlimaden';
import OyunVeDijitalKod from '../pages/test/bireysel/oyunvedijitalkod';
import ParaGonder from '../pages/test/bireysel/paragonder';
import ParaIste from '../pages/test/bireysel/paraiste';
import Bagis from '../pages/test/bireysel/bagis';
import ParaYukle from '../pages/test/bireysel/parayukle';
import KurumsalKart from '../pages/test/kurumsal/kurumsalkart';
import AndroidPos from '../pages/test/kurumsal/androidpos';
import AltinPos from '../pages/test/kurumsal/altınpos';
import CepPos from '../pages/test/kurumsal/ceppos';
import SanalPos from '../pages/test/kurumsal/sanalpos';
import KurumsalHesap from '../pages/test/kurumsal/kurumsalhesap';
import YazarKasa from '../pages/test/kurumsal/yazarkasa';
import TestKampanya from '../pages/test/kampanyalar/TestKampanya';
import TestKampanya1 from '../pages/test/kampanyalar/TestKampanya1';
import TestKampanya2 from '../pages/test/kampanyalar/TestKampanya2';
import TestKampanya3 from '../pages/test/kampanyalar/TestKampanya3';
import TestKampanya4 from '../pages/test/kampanyalar/TestKampanya4';
import TestKampanya5 from '../pages/test/kampanyalar/TestKampanya5';
import TestKampanya6 from '../pages/test/kampanyalar/TestKampanya6';
import TestKampanya7 from '../pages/test/kampanyalar/TestKampanya7';
import TestKampanya8 from '../pages/test/kampanyalar/TestKampanya8';
import TestKampanya9 from '../pages/test/kampanyalar/TestKampanya9';
import TestKampanya10 from '../pages/test/kampanyalar/TestKampanya10';
import TestKampanya11 from '../pages/test/kampanyalar/TestKampanya11';
import TestKampanya12 from '../pages/test/kampanyalar/TestKampanya12';
import TestKampanya13 from '../pages/test/kampanyalar/TestKampanya13';
import TestKampanya14 from '../pages/test/kampanyalar/TestKampanya14';
import TestKampanya15 from '../pages/test/kampanyalar/TestKampanya15';
import TestKampanya16 from '../pages/test/kampanyalar/TestKampanya16';
import TestKampanya17 from '../pages/test/kampanyalar/TestKampanya17';
import Representatives from '../pages/representatives/Representatives';

export const RouterList = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/basvuru",
        element: <ApplyPage openModal={true} setOpenModal={null} />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
    {
        path: "/bireysel-urunler/kiymetli-maden-alim-satim",
        element: <KiymetliMaden />,
    },
    {
        path: "/bireysel-urunler/ahlcard",
        element: <AhlCard />,
    },
    {
        path: "/bireysel-urunler/atom",
        element: <Atom />,
    },
    {
        path: "/bireysel-urunler/para-gonder",
        element: <ParaGonder />,
    },
    {
        path: "/bireysel-urunler/para-yukle",
        element: <ParaYukle />,
    },
    {
        path: "/bireysel-urunler/para-iste",
        element: <ParaIste />,
    },
    {
        path: "/bireysel-urunler/fatura-ode",
        element: <FaturaOde />,
    },
    {
        path: "/bireysel-urunler/yatirim-urunleri",
        element: <BireyselYatirim />,
    },
    {
        path: "/bireysel-urunler/oyun-ve-dijital-kod",
        element: <OyunVeDijitalKod />,
    },
    {
        path: "/bireysel-urunler/bagis",
        element: <Bagis />,
    },
    {
        path: "/bireysel-urunler/guvencebedeliiadesi",
        element: <GuvenceBedeli />,
    },
    {
        path: "/bireysel-urunler/fizikialtinvegumus",
        element: <FizikiAltinVeGumus />,
    },
    {
        path: "/kurumsal-urunler/kart",
        element: <KurumsalKart />,
    },
    {
        path: "/kurumsal-urunler/androidpos",
        element: <AndroidPos />,
    },
    {
        path: "/kurumsal-urunler/ceppos",
        element: <CepPos />,
    },
    {
        path: "/kurumsal-urunler/sanalpos",
        element: <SanalPos />,
    },
    {
        path: "/kurumsal-urunler/altinpos",
        element: <AltinPos />,
    },
    {
        path: "/kurumsal-urunler/okcpos",
        element: <YazarKasa />,
    },
    {
        path: "/kurumsal-urunler/kurumsal-hesap",
        element: <KurumsalHesap />,
    },
    {
        path: "/Kampanya",
        element: <TestKampanya />,
    },
    {
        path: "/Kampanya1",
        element: <TestKampanya1 />,
    },
    {
        path: "/Kampanya2",
        element: <TestKampanya2 />,
    },
    {
        path: "/Kampanya3",
        element: <TestKampanya3 />,
    },
    {
        path: "/Kampanya4",
        element: <TestKampanya4 />,
    },
    {
        path: "/Kampanya5",
        element: <TestKampanya5 />,
    },
    {
        path: "/Kampanya6",
        element: <TestKampanya6 />,
    },
    {
        path: "/Kampanya7",
        element: <TestKampanya7 />,
    },
    {
        path: "/Kampanya8",
        element: <TestKampanya8 />,
    },
    {
        path: "/Kampanya9",
        element: <TestKampanya9 />,
    },
    {
        path: "/Kampanya10",
        element: <TestKampanya10 />,
    },
    {
        path: "/Kampanya11",
        element: <TestKampanya11 />,
    },
    {
        path: "/Kampanya12",
        element: <TestKampanya12 />,
    },
    {
        path: "/Kampanya13",
        element: <TestKampanya13 />,
    },
    {
        path: "/Kampanya14",
        element: <TestKampanya14 />,
    },
    {
        path: "/Kampanya15",
        element: <TestKampanya15 />,
    },
    {
        path: "/Kampanya16",
        element: <TestKampanya16 />,
    },
    {
        path: "/Kampanya17",
        element: <TestKampanya17 />,
    },
    {
        path: "/Temsilciliklerimiz",
        element: <Representatives />,
    },
    {
        path: "/",
        unique: PageKeys.CONTACT_US,
        children: [
            {
                path: 'contact',
                element: <ContactPage />,
                languageCode: 'en',
                title: 'Contact Us'
            },
            {
                path: 'bize-ulasin',
                element: <ContactPage />,
                languageCode: 'tr',
                title: 'Bize Ulaşın'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.FAQ,
        children: [
            {
                path: 'faq',
                element: <FAQPage />,
                languageCode: 'en',
                title: 'Frequently Asked Questions'
            },
            {
                path: '/sss',
                element: <FAQPage />,
                languageCode: 'tr',
                title: 'Sıkça Sorulan Sorular'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.CAREER,
        children: [
            {
                path: 'career',
                element: <Career />,
                languageCode: 'en',
                title: 'Career'
            },
            {
                path: 'kariyer',
                element: <Career />,
                languageCode: 'tr',
                title: 'Kariyer'
            }
        ]
    },
    {
        path: '/career',
        element: <Career />
    },
    {
        path: '/career-detail',
        element: <CareerDetail />
    },
    {
        path: '/logo',
        element: <AboutLogos />
    },
    {
        path: '/media',
        element: <AboutMedia />
    }
    ,
    {
        path: '/',
        unique: PageKeys.CAMPAIGNS,
        children: [
            {
                path: 'campaigns',
                element: <CampaignsPage />,
                languageCode: 'en',
                title: 'Campaigns'
            },
            {
                path: 'campaigns',
                element: <CampaignsPage />,
                languageCode: 'tr',
                title: 'Kampanyalar'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: '/Agreements',
                element: <Agreements />,
                languageCode: 'en'
            },
            {
                path: '/sozlesmeler',
                element: <Agreements />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/iso',
        element: <Certificates />
    },
    {
        path: '/',
        children: [
            {
                path: '/forms',
                element: <Forms />,
                languageCode: 'en'
            },
            {
                path: '/formlar',
                element: <Forms />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: '/legal-information',
                element: <LegalInfos />,
                languageCode: 'en'
            },
            {
                path: '/yasal-bilgiler',
                element: <LegalInfos />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'about-us',
                element: <AboutUsPage />,
                languageCode: 'en'
            },
            {
                path: 'hakkimizda',
                element: <AboutUsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.FEES_AND_LIMITS,
        children: [

            {
                path: 'ucret-ve-limitler',
                element: <FeesAndLimitsPage />,
                languageCode: 'tr',
                title: 'Ücret ve Limitler'
            },
            {
                path: 'fees-and-limits',
                element: <FeesAndLimitsPage />,
                languageCode: 'en',
                title: 'Fees & Limits'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.BLOGS,
        children: [
            {
                path: 'blogs',
                element: <BlogPage />,
                languageCode: 'en',
                title: 'Blog'
            },
            {
                path: 'blogs',
                element: <BlogPage />,
                languageCode: 'tr',
                title: 'Blog'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'blog-detail',
                element: <BlogDetailsPage />,
                languageCode: 'en'
            },
            {
                path: 'blog-detail',
                element: <BlogDetailsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanyalar',
                element: <CampaignsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanyalar',
                element: <CampaignsPage />,
                languageCode: 'en'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanya-detay',
                element: <CampaignsPage />,
                languageCode: 'tr'
            }
        ]
    },
    {
        path: '/',
        children: [
            {
                path: 'kampanya-detay',
                element: <CampaignsPage />,
                languageCode: 'en'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_INDIVIDUAL_V2,
        children: [
            {
                path: 'products-corporate-old',
                element: <ProductCorporatePage />,
                languageCode: 'en',
                title: 'Corporate'
            },
            {
                path: 'products-corporate-old',
                element: <ProductCorporatePage />,
                languageCode: 'tr',
                title: 'Kurumsal'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_INDIVIDUAL,
        children: [
            {
                path: '/bireysel-urunler/kiymetli-maden-alim-satim',
                element: <KiymetliMaden />,
                languageCode: 'en',
                title: 'Retail'
            },
            {
                path: '/bireysel-urunler/kiymetli-maden-alim-satim',
                element: <KiymetliMaden />,
                languageCode: 'tr',
                title: 'Bireysel'
            }
        ]
    },
    {
        path: '/',
        unique: PageKeys.PRODUCTS_CORPORATE,
        children: [
            {
                path: '/kurumsal-urunler/kart',
                element: <KurumsalKart />,
                languageCode: 'en',
                title: 'Corporate'
            },
            {
                path: '/kurumsal-urunler/kart',
                element: <KurumsalKart />,
                languageCode: 'tr',
                title: 'Kurumsal'
            }
        ]
    },
];

export const GetRouteDetail = (uniqueKey, language) => {
    return RouterList.find(x => x.unique == uniqueKey).children.find(x => x.languageCode == language);
}

export const router = () => { return createBrowserRouter(RouterList); }


// {
//     path: '/',
//     unique: PageKeys.PRODUCTS_INDIVIDUAL,
//     children: [
//         {
//             path: 'products-individual',
//             element: <ProductIndividualV2Page />,
//             languageCode: 'en',
//             title: 'Retail'
//         },
//         {
//             path: 'products-individual',
//             element: <ProductIndividualV2Page />,
//             languageCode: 'tr',
//             title: 'Bireysel'
//         }
//     ]
// },
// {
//     path: '/',
//     unique: PageKeys.PRODUCTS_CORPORATE,
//     children: [
//         {
//             path: 'products-corporate',
//             element: <ProductCorporateV2Page />,
//             languageCode: 'en',
//             title: 'corporate'
//         },
//         {
//             path: 'products-corporate',
//             element: <ProductCorporateV2Page />,
//             languageCode: 'tr',
//             title: 'Kurumsal'
//         }
//     ]
// },







