// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1><br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt=""
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihi
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            id: 2,
            pageTitle: 'Fiziki Altın Gümüş',
            headerTitle: 'Fiziki Altın/Gümüş',
            title: "Fiziki Altın ve Fiziki Gümüş Satışı İlk Kez AHL Pay'de !",
            shortDescription: "Bir ilke imza attık, adrese teslim fiziki altın ve gümüş satışlarına başladık. AHL Pay kullanıcılarının mobil uygulama üzerinden satın aldıkları altın ve gümüş ürünler, siparişte belirtilen adrese sigortalı olarak teslim ediliyor.",
            description: "AHL Pay uygulaması üzerinden fiziki altın ve gümüş alımı yapabilirsin. AHL Pay ile anlık fiyatlarla altın ve gümüş alabilir, siparişlerini sigortalı olarak kapına teslim ettirebilirsin.",
            imagePath: 'assets/uploads/products/phones/fiziki_phone.svg',
            title2: <><span>Fiziki Altın ve Gümüş</span> Satışı</>,
            childrens: [
                {
                    title: 'Adrese Teslim',
                    desc: 'Fiziki Altın ve Gümüşlerin adresine teslim edilsin'
                },
                {
                    title: 'Avantajlı Fiyat',
                    desc: 'Avantajlı fiyatlar ile yatırım yap'
                },
                {
                    title: 'Yatırımda Güvenli Liman',
                    desc: 'Siparişin sana ulaşana kadar Ahlatcı Kuyumculuk sigortası altında'
                }
            ]
        },
        en: {
            id: 2,
            pageTitle: 'Physical Gold Silver',
            headerTitle: 'Physical Gold/Silver',
            title: "Physical Gold and Silver Sales for the First Time on AHL Pay!",
            shortDescription: "We've made a breakthrough by starting doorstep delivery of physical gold and silver sales. The gold and silver products purchased by AHL Pay users via the mobile app are insured and delivered to the specified address.",
            description: "You can buy physical gold and silver through the AHL Pay app. With AHL Pay, you can purchase gold and silver at real-time prices, and have your orders insured and delivered to your doorstep.",
            imagePath: 'assets/uploads/products/phones/fiziki_phone.svg',
            title2: <><span>Physical Gold and Silver</span> Sales</>,
            childrens: [
                {
                    title: 'Doorstep Delivery',
                    desc: 'Have your physical gold and silver delivered to your address'
                },
                {
                    title: 'Competitive Pricing',
                    desc: 'Invest at competitive prices'
                },
                {
                    title: 'Secure Investment',
                    desc: 'Your order is insured by Ahlatcı Kuyumculuk until it reaches you'
                }
            ]
        }
    };

    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br /><br /><br /><br /><br /><br />
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
