import { Link } from 'react-router-dom';
  import styled from 'styled-components';

  const NavBarContainer = styled.div`
    width: 300px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `;
  const NavList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
  `;
  const NavItem = styled.li`
    margin-bottom: 15px;
  `;
  const NavLink = styled(Link)`
    text-decoration: none;
    color: #6c757d;
    font-size: 16px;
    font-weight: 500;
    &:hover {
      color: #0056b3;
    }
  `;

const KampanyaNavBar = () => {
  return (
    <NavBarContainer>
      <NavList>
      <NavItem>
          <NavLink to="/Kampanya3">Herkese Galatasaray Atkısı</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya1">Galatasaray Stad ve Müze Gezisi</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya2">Soruyu Bil, İmzalı forma ve şort kazan</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya4">AYTEMİZ'de %3'e varan indirim</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya5">JACK&JONES'ta geçerli %20 İndirimi</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya6">ARABİCA COFFEE HOUSE'ta %10 İndirim</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya7">OPEN ENGLİSH Kampanyası</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya8">BRILLANTSTORE'dan %30 İndirim + Ücretsiz Kargo</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya9">* 7/11/2024 GS - Tot Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya">* 10/11/2024 GS - Sam maçı bilet Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya10">* 28.10.2024 GS - BJK Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya11">* 23.10.2024 GS - ELF Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya12">* 06.10.2024 GS - ALY Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya13">* 28.09.2024 GS - KAS Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya14">* 25.09.2024 GS - PAOK Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya15">* 17.09.2024 GS - GFK Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya16">* 14.09.2024 GS - RİZ Maçı Talihlileri</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/Kampanya17">* 27.08.2024 GS - YGB Maçı Talihlileri</NavLink>
        </NavItem>
      </NavList>
    </NavBarContainer>
  );
};

export default KampanyaNavBar;
